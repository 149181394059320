import React, { useEffect, Component } from "react";
// import GoogleMapReact from 'google-map-react';
import {
  GoogleMap,
} from "@react-google-maps/api";
// import { fitBounds } from 'google-map-react';
import { Box, Grid } from "@mui/material";

import DeviceMarker from "./DeviceMarker";
import getMapsAPIToken from "../../config/constants/maps";

import "./marker.css";


function applyMax(accum, b) {
  if (accum === null || accum === undefined) {
    return b;
  }
  return Math.max(accum, b);
}

function applyMin(accum, b) {
  if (accum === null || accum === undefined) {
    return b;
  }
  return Math.min(accum, b);
}



// function MapMarker(props) {
//   let [streetLocation, setStreetLocation] = useState('');

//   useEffect(() => {
//     if (streetLocation === '') {
//       reverseGeocodeCall(props.position, function(address) {
//         setStreetLocation(address);
//       })
//     }
//   }, [streetLocation, props.position]);

//   return (
//     <Box>
//       <Grid container spacing={2}>
//         <Grid item xs={3}>
//           <GpsFixedIcon />
//         </Grid>
//         <Grid item xs={9}>
//           <p>{props.deviceName}</p>
//           <p>{streetLocation}</p>
//         </Grid>
//       </Grid>
//     </Box>
//   )
// }

class GoogleMapPane extends Component {
  constructor(props) {
    super(props);
    // if (this.props.devices.length) {
    //   let bounds = this.fitMapToAllDevices();
    //   this.state = {
    //     center: bounds.center,
    //     zoom: bounds.zoom
    //   };
    // } else {

    this.state = {
      center: props.center,
      zoom: props.zoom,
      mapsAPIKey: null
    };
    // }
  }

  static defaultProps = {
    center: {
      // center of cont. US
      lat: 39.8283,
      lng: -98.5795,
    },
    zoom: 5,
  };
  // static defaultProps = {
  //   center: {
  //     lat: 39,
  //     lng: -90
  //   },
  //   zoom: 6
  // };

  handleCentering() {
    if (this.mapInstance) {
      console.log("Map loaded for zoom");
      let bounds = new window.google.maps.LatLngBounds();
      if (this.props.devices?.length) {
        this.props.devices.forEach((device) => {
          if (
            device.lastLocation &&
            device.lastLocation.location &&
            device.lastLocation.location.coordinates.length
          ) {
            bounds.extend(
              new window.google.maps.LatLng(
                device.lastLocation.location.coordinates[1],
                device.lastLocation.location.coordinates[0]
              )
            );
          } else {
            console.log("Device " + device.deviceID + " skipped");
          }
        });
        console.log(bounds);
        this.mapInstance.fitBounds(bounds);
        this.mapInstance.setZoom(Math.min(this.mapInstance.getZoom(), 10));
      }
    }
  }

  handleApiLoaded(map, maps) {}

  onInfoWindowClose = () => {};

  renderMarkers() {
    if (!this.props.devices) {
      return <div></div>;
    }

    const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
      return {
        x: offsetWidth + labelAnchor.x,
        y: offsetHeight + labelAnchor.y,
      };
    };

    console.log(this.props.devices);
    return this.props.devices.map((device) => (
      <DeviceMarker key={device.deviceID} device={device} assetMode={this.props.assetMode} id={device.deviceID} />
    ));
  }

  fitMapToAllDevices() {
    let boundsObj = {
      center: this.props.center,
      zoom: this.props.zoom,
    };

    // single device case
    if (
      this.props.devices &&
      this.props.devices.length === 1 &&
      this.props.devices[0].lastLocation &&
      !isNaN(this.props.devices[0].lastLocation.location.coordinates[0]) &&
      !isNaN(this.props.devices[0].lastLocation.location.coordinates[1])
    ) {
      boundsObj.center = {
        lat: this.props.devices[0].lastLocation.location.coordinates[1],
        lng: this.props.devices[0].lastLocation.location.coordinates[0],
      };
      boundsObj.zoom = 16;
    } else if (this.props.devices && this.props.devices.length > 1) {
      // let bounds = new GoogleMapReact.LatLngBounds();
      let bounds = {
        ne: {
          // most positive
          lat: null,
          lng: null,
        },
        sw: {
          // most negative
          lat: null,
          lng: null,
        },
      };
      this.props.devices.forEach(function (device) {
        if (
          device.lastLocation &&
          device.lastLocation.location.coordinates[0] &&
          device.lastLocation.location.coordinates[1]
        ) {
          console.log(
            "Device coords: " + device.lastLocation.location.coordinates
          );
          // bounds.extend(new GoogleMapReact.LatLng(device.lastLocation.location.coordinates[1], device.lastLocation.location.coordinates[0]));
          bounds = {
            ne: {
              lat: applyMax(
                bounds.ne.lat,
                device.lastLocation.location.coordinates[1]
              ),
              lng: applyMax(
                bounds.ne.lng,
                device.lastLocation.location.coordinates[0]
              ),
            },
            sw: {
              lat: applyMin(
                bounds.sw.lat,
                device.lastLocation.location.coordinates[1]
              ),
              lng: applyMin(
                bounds.sw.lng,
                device.lastLocation.location.coordinates[0]
              ),
            },
          };
        }
      });
      // FitBounds requires the mapSize. TODO: get this dynamically from the map
      // const size = {
      //   width: 640,
      //   height: 380
      // }
      // boundsObj = fitBounds(bounds, size);
      const zoomMult = 10.0;
      const zoom =
        (1.0 /
          Math.max(
            bounds.ne.lat - bounds.sw.lat,
            bounds.ne.long - bounds.sw.long
          )) *
        zoomMult;

      boundsObj = {
        center: {
          // naive midpoint
          lat: (bounds.ne.lat + bounds.sw.lat) / 2.0,
          lng: (bounds.ne.long + bounds.sw.long) / 2.0,
        },
        zoom: zoom,
      };
    }
    return boundsObj;
  }

  componentDidUpdate = () => {
    this.handleCentering();
  };

  componentDidMount = () => {
    getMapsAPIToken().then((token) => {
      this.setState({
        mapsAPIKey: token,
      });
    });
  };

  handleOnMapLoad = (map) => {
    console.log("Map loaded");
    console.log("Old state:");
    console.log(this.state);
    // let newState = this.fitMapToAllDevices();
    // newState.map = map;
    this.mapInstance = map;
    // console.log("New State:");
    // console.log(newState);
    // this.setState(newState);
    setTimeout(() => {
      this.handleCentering();
    }, 1000);

    // ;
    // this.state.map.panTo(this.state.center);
  };

  render() {
    // let boundsObj = {
    //   center: [45, -90],
    //   zoom: 7
    // };

    // console.log("center:");
    // console.log(boundsObj.center);

    // const containerStyle = {
    //   height: "42vh",
    // };

    // hack for allowing assets
    if (this.props.assetMode && this.props.devices?.length) {
      this.props.devices.forEach((asset) => {
        asset.deviceID = asset._id;
      });
    }

    if (!this.state.mapsAPIKey) {
      return <div>Loading Maps API...</div>;
    }

    return (
      // Important! Always set the container height explicitly
      <Box display="flex" flexDirection="column" style={{ width: "100%" }}>
          <Box flexGrow={1}>
            <GoogleMap
              mapContainerStyle={this.props.containerStyle}
              style={this.props.mapStyle}
              center={this.state.center}
              zoom={this.state.zoom}
              onLoad={this.handleOnMapLoad}
              options={{
                gestureHandling: 'greedy'
              }}
            >
              {this.props.devices?.map((device) => (
                <DeviceMarker key={device.deviceID} assetMode={this.props.assetMode} device={device} id={device.deviceID} />
              ))}
            </GoogleMap>
          </Box>
        {/* <GoogleMapReact
          bootstrapURLKeys={{
            key: mapsAPIKey
          }}
          // defaultCenter={this.props.center}
          center={this.state.center}
          zoom={this.state.zoom}
          // defaultZoom={this.props.zoom}
          // center={boundsObj.center}
          // zoom={boundsObj.zoom}
          // yesIWantToUseGoogleMapApiInternals
          // onGoogleApiLoaded={(map, maps) => this.handleApiLoaded(map, maps)}
        >
          {this.renderMarkers()}
        </GoogleMapReact> */}
      </Box>
    );
  }
}

export default GoogleMapPane;
