import React, { Component } from 'react';

import { Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

import MyProfileForm from '../components/MyProfileForm';
import httpClient from '../httpClient';

export default class MyProfileView extends Component {

    // initialize state
    state = {
        user: this.props.user
    }

    // refresh user data
    componentDidMount() {
        httpClient.get(
            `/user/${this.props.user._id}`
        ).then((response) => {
            this.setState({ user: response.data.user });
        }).catch((err) => {
            console.log("Error getting user data");
            console.log(err);
        });
    }

    render() {
        return (
            <>
                <MyProfileForm user={this.props.user} />
                {
                    (httpClient.currentUserIsOrgAdmin()) ? (
                        <>
                            <Divider sx={{marginTop: 3}}/>
                            <Button sx={{marginTop: 3}} variant="contained" color="primary" component={Link} to="/orgmanage">
                                Manage Organization
                            </Button>
                        </>
                ) : <></>
                }
            </>
        )
    }
}