// Custom higher-order-component wrapper that passes URL params from the Router context
import React from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';


const withRouter = (WrappedComponent) => (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const searchParams = useSearchParams();
    return <WrappedComponent {...props} params={params} navigate={navigate} searchParams={searchParams} />;
};

export default withRouter;
