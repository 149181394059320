import React, { useState } from 'react';
import * as yup from 'yup';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import httpClient from '../httpClient';
import { baseURL } from '../config/constants/url';

const validationSchema = yup.object().shape({
  keyText: yup
    .string('Enter an activation key')
    .required('Enter an activation key')
});

export default function LinkDeviceView(props) {
  let [keyResponses, setKeyResponses] = useState([]);

  const handleSubmit = (values) => {
    // Old multi-key method
    // let keys = values.keysText.split(/[\s\n,;]+/);   // split on space, comma, newline, etc
    // httpClient.post(
    //   new URL('/user/bulkregister', baseURL),
    //   {
    //     keys: keys,
    //     userID: props.user._id
    //   },
    // ).then((response) => {
    //   // iterate through list of responses to check for devices which need for access requests
    //   response.data.keyResponses.forEach((keyResponse) => {
    //     if (keyResponse.status === 'accessRequest') {
    //       httpClient.post(
    //         new URL('/user-roles/request/', baseURL),
    //         {
              
    //           message: 'You have an access request for device ' + keyResponse.key + ' from ' + 
    //             keyResponse.requestingUser.fullName + ' (' + keyResponse.requestingUser.email + ')',
    //           deviceId: keyResponse.key,
    //           senderId: keyResponse.requestingUser._id.toString(),
    //           receiverId: keyResponse.receiverId.toString()
              
    //         }
    //       ).catch((reason) => {
    //         console.log("error sending userRuleRequest");
    //         console.log(reason);
    //       });
    //     }
    //   });

    //   setKeyResponses(response.data.keyResponses);
    // }).catch((reason) => {
    //   console.log("Error when submitting activation keys:");
    //   console.log(reason);
    // })

    // New single-key method
    
    // First check if the key is already registered
    httpClient.get('/device/check-exist/' + values.keyText).then((response) => {
      if (!response.data.status) {
        console.log("Device does not exist in the database");
        // TODO: give user feedback that the key was invalid
        setKeyResponses([{
          status: 'error',
          key: values.keyText,
          message: 'Device ' + values.keyText + ' does not exist in the database'
        }]);
        return;
      } else {
        if (response.data.deviceCount > 0) {
          // device is already registered, so send an access request
          httpClient.post(
            '/user-roles/request/',
            {
              receiverId: response.data.result.user,
              senderId: props.user._id,
              deviceId: values.keyText,
              message: 'You have an access request for hubodometer ' + values.keyText + ' from ' + 
                props.user.fullName + ' (' + props.user.email + ')'
            }
          ).then((response) => {
            console.log("Access request sent successfully");
            setKeyResponses([{
              status: 'accessRequest',
              key: values.keyText,
              message: 'Access request sent for device ' + values.keyText
            }]);
          }).catch((reason) => {
            console.log("Error sending access request");
            console.log(reason);
          });
        } else if (response.data.deviceCount === 0) {
          // device is not registered, so update device doc with user ID to register it
          const updateObject = {
            user: props.user._id,
            organization: props.user.organization
          };
          httpClient.put(
            '/device/' + values.keyText,
            updateObject
          ).then((response) => {

            // TODO: link to next step in hubo registration process

            console.log("Device registered successfully");
            setKeyResponses([{
              status: 'registered',
              key: values.keyText,
              message: 'Device ' + values.keyText + ' registered successfully'
            }]);
          }).catch((reason) => {
            console.log("Error registering device");
            console.log(reason)
          });
        }
      }
    }).catch((reason) => {
      console.log("Error when attempting to link device");
      console.log(reason);
    });

  };

  return (
    <div>
      <Formik
        initialValues={{
          // keysText: "",
          keyText: ""
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <Typography variant='h6'>
              To register a hubodometer with your account, enter the device's activation key in the box below.
            </Typography>
            <br></br>
            {/* <TextareaAutosize
              style={{
                width: '500px',
                boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.25)',
              }}
              id="keysText"
              name="keysText"
              label="Activation Key(s)"
              minRows={4}
              maxRows={16}
              value={formikProps.values.keysText}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              error={formikProps.touched.keys && Boolean(formikProps.errors.keys)}
              // helperText={formikProps.touched.email && formikProps.errors.email}
            /> */}
            <TextField
              style={{
                width: '500px',
                boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.25)',
              }}
              id="keyText"
              name="keyText"
              label="Activation Key"
              value={formikProps.values.keyText}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              error={formikProps.touched.keyText && Boolean(formikProps.errors.keyText)}
              helperText={formikProps.touched.keyText && formikProps.errors.keyText}
            />
            <br></br><br></br>
            <Button color="primary" variant="contained" type="submit" style={{ boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.25)' }}>
              Register Hubodometer to Account
            </Button>
            <br /><br />
            <>
              {
                keyResponses.map(keyResponse => {
                  if (keyResponse.status === 'registered') {
                    return <Alert key={"alert-" + keyResponse.key} severity="success">{keyResponse.message}</Alert>
                  } else if (keyResponse.status === 'accessRequest') {
                    return <Alert key={"alert-" + keyResponse.key} severity="info">{keyResponse.message}</Alert>
                  } else {
                    // error
                    return <Alert key={"alert-" + keyResponse.key} severity="error">{keyResponse.message}</Alert>
                  }
                })
              }
            </>

          </form>
        )}
      </Formik>

    </div>
  )
}