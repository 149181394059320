import React, { Component } from 'react';

// import Geocode from 'react-geocode';
import { InfoWindowF, MarkerF, CircleF } from '@react-google-maps/api';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { Grid } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Link } from 'react-router-dom';

import withGoogleMap from './withGoogleMap';
import { baseURL } from '../../config/constants/url';
// import { mapsAPIKey } from '../../config/constants/maps';

import { reverseGeocodeCall } from '../../utils';

const TRUCK_ICON = '/truck_icon.svg';
//const MIN_ACCURACY_FOR_CIRCLE = 15      // in meters 
const MIN_ACCURACY_FOR_CIRCLE = 0      // always show 

class DeviceMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textLocation: '',
      isExpanded: false

    };
  }

  componentDidUpdate = () => {
    if (this.state.textLocation === '') {
      reverseGeocodeCall(this.props.device.lastLocation, (address) => {
        this.setState({
          textLocation: address
        });
      })
    }
  }

  setInfoWindowInstance = (infoWindow) => {
    this.infoWindow = infoWindow;
  }

  handleToggleOpen = () => {
    if (this.infoWindow) {
      this.infoWindow.open(this.props.map);
    }
    this.setState({ isExpanded: true });
  }

  handleToggleClose = () => {
    this.infoWindow.close();
    this.setState({ isExpanded: false });
  }

  handleMarkerLoad = (marker) => {
    this.markerInstance = marker;
  }

  render = () => {
    const device = this.props.device;

    let deviceLink;
    if (this.props.assetMode) {
      deviceLink = '/assetdetail/' + device.deviceID;
    } else {
      deviceLink = '/devicedetail/' + device.deviceID;
    }
    // const handleToggleOpen = this.handleToggleOpen;
    // const handleToggleClose = this.handleToggleClose;
    const markerInstance = this.markerInstance;
    let isExpanded = this.state.isExpanded;
    // console.log(device.deviceID + " isExpanded: " + isExpanded);
    if (device.lastLocation?.location?.coordinates !== undefined) {
      console.log(`Device ${device.name} lastLocation:`);
      // console.log(`${device.lastLocation.location.coordinates[1]}, ${device.lastLocation.location.coordinates[0]}`);
      const deviceLatLng = {
        lat: device.lastLocation.location.coordinates[1],
        lng: device.lastLocation.location.coordinates[0]
      }
      const locationAccuracy = device.lastLocation.accuracyMeters;
      console.log(JSON.stringify(deviceLatLng))
      const locationTime = device.lastLocation.ts;

      // console.log(this.state);
      return (
        <>
          <MarkerF
            key={device.deviceID}
            title={device.name}
            position={deviceLatLng}
            // Truck icon dimensions: 48x48
            icon={{
              url: TRUCK_ICON,
              scaledSize: { width: 48, height: 48 },
              anchor: { x: 24, y: 24 }
            }}
            onClick={this.handleToggleOpen}
            onLoad={this.handleMarkerLoad}
          >
            {(isExpanded) ? (
              <InfoWindowF
                key={`infowindow-${this.props.device.name}`}
                anchor={markerInstance}
                // position={deviceLatLng}
                onLoad={this.setInfoWindowInstance}
                onCloseClick={this.handleToggleClose}
                options={{
                  minWidth: '100px'
                }}
                sx={{
                  'button.gm-ui-hover-effect': {
                    visibility: 'hidden'
                  }
                }}
              >
                <Grid container>
                  <Grid item xs={1}>
                    <GpsFixedIcon style={{ marginRight: '6px' }} />
                  </Grid>
                  <Grid item xs={11}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <b>{this.props.assetMode ? 'Asset' : 'Device'}</b>:
                            </TableCell>
                            <TableCell align="right">
                              <Link to={deviceLink}>{this.props.device.name}</Link>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>Timestamp</b>:
                            </TableCell>
                            <TableCell align="right">
                              {locationTime}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>Address</b>:
                            </TableCell>
                            <TableCell align="right">
                              {this.state.textLocation}
                            </TableCell>
                          </TableRow>
                          {(locationAccuracy !== undefined) ? (
                            <TableRow>
                              <TableCell>
                                <b>Location Accuracy</b>:
                              </TableCell>
                              <TableCell align="right">
                                within {locationAccuracy} m
                              </TableCell>
                            </TableRow>
                          ) : <></>
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>


                  </Grid>
                </Grid>
              </InfoWindowF>
            ) : <></>}
          </MarkerF>
          {(locationAccuracy !== undefined && locationAccuracy > MIN_ACCURACY_FOR_CIRCLE) ? 
            <CircleF 
              key={device.deviceID + '_acc'}
              center={deviceLatLng}
              radius={locationAccuracy}
              options={{
                fillColor: '#1462ac',
                fillOpacity: 0.15,
                strokeColor: "#1462ac",
                strokeOpacity: 0.5
              }}
            /> :
            <></>
          }
        </>
      )
    }
    console.log("Device missing location: " + device.deviceID);
    return (<div key={device.deviceID}></div>);
  }
}


export default withGoogleMap(DeviceMarker);
