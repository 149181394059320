import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    // Card,
    // CardContent,
    Grid,
    List,
    ListItem,
    Typography
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import httpClient from '../httpClient';

import styles from '../panels/devicelist.module.css';

const AssetSearchBar = (props) => {

    return (
        <div className="searchbar">
            <Form.Control
                id="searchField"
                type="search"
                onChange={props.searchInputHandler}
                placeholder="Find an asset..."
                style={{
                    width: '25%'
                }}
            />
        </div>
    )
}

function AlertElement(props) {
    if (!props.alertCount) {
        // return OK
        return (
            <div style={{ color: 'green' }}>
                <CheckCircleOutlineIcon fontSize='large' />
                <p>No active alerts</p>
            </div>
        )
    } else {
        return (
            <div style={{ color: 'red' }}>
                <WarningAmberIcon fontSize='large' />
                <p><a href='/alerts'>{props.alertCount} active alerts</a></p>
            </div>
        )
    }
}

const AssetCard = (props) => {
    const asset = props.asset;

    const [tripHistory, setTripHistory] = useState(null);
    const cardID = 'dbelement-' + asset._id;

    useEffect(() => {
        if (!tripHistory) {
            httpClient.get(
                '/asset/trip-history/' + asset._id,
            ).then((response) => {
                setTripHistory(response.data.tripHistory);
            });
        }
    });

    let alertCount = (props.numActiveAlerts ? props.numActiveAlerts : 0);

    let oldestHistoryText = 'No trip history available';
    if (tripHistory?.length) {
        const oldestDate = tripHistory[tripHistory.length - 1].ts;
        oldestHistoryText = 'History available since ' + new Date(oldestDate).toLocaleDateString();
    }

    return (
        <Col sm={12}>
            <Card id={cardID} className={styles.DeviceCard}>
                <Card.Body>
                    <Row>
                        {asset.imageUrl ? (
                            <Col md={2}>
                                <Box 
                                    src={asset.imageUrl}
                                    alt={asset.name}
                                    component="img"
                                    sx={{
                                        borderRadius: "4px",
                                        width: "140px",
                                        height: "130px",
                                    }}
                                />
                            </Col>
                        ) : (
                            <></>
                        )}
                        <Col md={asset.imageUrl ? 2 : 4}>
                            <p>ASSET NAME</p>
                            <Link to={'/assetdetail/' + asset._id}>
                                <h3>{asset.name}</h3>
                            </Link>
                        </Col>
                        <Col sm={4}>
                            <Row>
                                <Col sm={6}>
                                    {/* Alerts */}
                                    <p>ALERTS</p>
                                    <AlertElement alertCount={alertCount} />
                                </Col>
                                <Col sm={6}>
                                    {/* Link to location history? */}
                                    {/* <DirectionsIcon fontSize='large'></DirectionsIcon> */}
                                    <p>ODOMETER READING</p>
                                    <h3>{asset.currentMileage.toFixed(1)} mi</h3>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Link to={'/assettriphistory/' + asset._id}>
                                {/* <p><LocationOnIcon /> Trip History:</p> */}
                                <p>TRIP HISTORY</p>
                                <h4><small>{oldestHistoryText}</small></h4>
                            </Link>
                        </Col>
                        {/*
                        <Col>
                            <p>NAME</p>
                            <h3>{props.name}</h3>
                        </Col>
                        <Col>
                            <p>CUMULATIVE DISTANCE</p>
                            <h3>{props.cumDistance.toFixed(1)} mi</h3>
                        </Col>
                        <Col>
                            <p>AVERAGE DAILY MILEAGE</p>
                            <h3>{props.dailyMileage}</h3>
                        </Col>
                        <Col>
                            <p>PERCENT IDLE TIME</p>
                            <h3>{props.percentIdle}</h3>
                        </Col>
                        <Col>
                            <p>ACTIVE ALERTS</p>
                            <h3>{alertCount}</h3>
                        </Col> */}

                    </Row>

                    {/* <Grid container>
                    <Grid item sm={4}>
                        <Typography gutterBottom variant='body1'>ASSET NAME</Typography>
                        <Typography gutterBottom variant="body1">
                            {asset.name}
                            <Link to={"/assetdetail/" + asset._id}>
                                {asset._id}
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item sm={3}>
                        <Typography gutterbottom variant="body1">
                            Total Mileage
                        </Typography>
                        <br></br>
                        <Typography variant="body1">
                            {asset.lifetimeMileage.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item s>
                        <Typography gutterbottom variant="h5">
                            Current Mileage:
                        </Typography>
                        <br></br>
                        <Typography variant="h5">
                            {asset.currentMileage?.toFixed(2)}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography gutterbottom variant="h5">
                            Lifetime Mileage:
                        </Typography>
                        <br></br>
                        <Typography variant="h5">
                            {asset.lifetimeMileage?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterbottom variant="h5">
                            Lifetime Revs:
                        </Typography>
                        <br></br>
                        <Typography variant="h5">
                            {asset.lifetimeRevs}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterbottom variant="h5">
                            Latest Average Speed:
                        </Typography>
                        <br></br>
                        <Typography variant="h5">
                            {asset.averageSpeed?.toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid> */}
                </Card.Body>
            </Card>
        </Col>
    )
}

const AssetList = (props) => {
    const [searchFilter, setSearchFilter] = useState('');
    const assets = props.assets === undefined ? [] : props.assets;
    const searchInputHandler = (e) => {
        setSearchFilter(e.target.value);
    };

    if (!assets || assets.length === 0) return (<div>No assets found</div>);

    return (
        <>
            <Typography variant='h4'>All Assets</Typography>
            {/* <DeviceMap devices={this.state.devices} /> */}

            <br />
            <div style={{
                width: '100%',
                paddingLeft: '15px',
                paddingRight: '15px',
                marginBottom: '40px',
                display: 'block'
            }}>
                <Link to='/create-asset?returnTo=/assets'>
                    <Button variant='contained' style={{float: 'right'}}>Create New Asset</Button>
                </Link>
                <AssetSearchBar searchInputHandler={searchInputHandler} style={{ float: 'left', marginTop: '0px' }} />
            </div>
            {/* <List> */}
            {assets.map((asset) => {
                if (
                    searchFilter.length && (
                        !asset._id?.toLowerCase().includes(searchFilter.toLowerCase()) &&
                        !asset.name?.toLowerCase().includes(searchFilter.toLowerCase()) &&
                        !asset.description?.toLowerCase().includes(searchFilter.toLowerCase())
                    )
                ) {
                    // this device does not fit the search filter
                    return "";
                }
                return (
                    // <ListItem>
                    <AssetCard asset={asset} key={asset._id + "-assetcard"}/>
                    // </ListItem>
                )
            })}
            {/* </List> */}
        </>
    )
}

export default AssetList;
