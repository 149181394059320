import React from 'react';

import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Link } from 'react-router-dom';

import httpClient from '../httpClient';


const EnterDeviceKeyForm = (props) => {
    const validationSchema = yup.object({
        deviceKey: yup.string().required()
    });

    const handleSubmit = (values) => {
        
        if (!props.user.organization) {
            alert("There is a problem with your organization account. Please contact support.");
            return;
        }

        httpClient.post(
            '/device/',
            {
                deviceID: values.deviceKey,
                name: values.name,
                user: props.user.id,
                organization: props.user.organization,
            }
        ).then((response) => {
            console.log(response);

            // TODO: Add other steps to flow
            props.advanceStage(3);
        }).catch((reason) => {
            console.log("Error linking device:");
            console.log(reason);
            if (reason.response?.data?.subscriptionStatus !== undefined) {
                alert("There is a problem with your current subscription. Please contact support.");
            }
            alert("There was an error linking your Hubo. Please check the activation code and try again.");
        });
    }

    const formik = useFormik({
        initialValues: {
            deviceKey: '',
            name: ''
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    if (!httpClient.currentUserIsOrgAdmin()) {
        return (
            <Box>
                <Typography variant='h6'>You do not have permission to link a Hubo. Please contact your organization administrator.</Typography>
            </Box>
        )
    }

    return (
        <Box sx={{
            width: '50em'
        }}>
            <form onSubmit={formik.handleSubmit}>
                <Typography gutterBottom variant='h6'>Please enter the serial number included with the Hubo:</Typography>
                <TextField
                    id="deviceKey"
                    name="deviceKey"
                    label="Hubo Serial Number / Activation Code"
                    fullWidth
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.deviceKey}
                    error={formik.touched.deviceKey && Boolean(formik.errors.deviceKey)}
                    helperText={formik.touched.deviceKey && formik.errors.deviceKey}
                />
                <br /><br />
                <TextField
                    id="name"
                    name="name"
                    label="Name (Optional)"
                    fullWidth
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
                <Typography gutterBottom>{formik.errors.deviceKey}</Typography>
                <br />
                <Typography variant='caption' gutterBottom>
                    <p>Linking a new Hubo to your account will add it to your TracMile subscription, at the rate of $99.00 / Hubo / year upfront. 
                    The amount will be prorated based on the remaining time left in your current subscription period, 
                    ensuring you only pay for the duration until your subscription renewal.</p>
                    
                        <p>When you click the Submit button below: </p>
                        <ul>
                            <li>The Hubo will be added to your account</li>
                            <li>Your saved payment method will be charged with the prorated upgrade amount</li>
                        </ul> 
                </Typography>
                <Button variant="contained" type="submit">Submit</Button>
            </form>
        </Box>
    )
}

const ConfirmNewSubscription = (props) => {
    const user = props.user;

    return (
        <Box>
            <h1>Confirm New Subscription</h1>
            <p>Subscription: {user.subscription}</p>
            <Button>Confirm</Button>
        </Box>
    )
}

const LinkDeviceFlowView = (props) => {
    const user = props.user;
    const [stage, setStage] = React.useState(0);

    const advanceStage = (stageNumber) => {
        if (stageNumber !== undefined) {
            setStage(stageNumber);
            return;
        }
        setStage(stage + 1);
    }

    return (
        <>
            {stage === 0 && (
                <EnterDeviceKeyForm user={user} advanceStage={advanceStage}/>
            )}
            {stage === 1 && (
                <h1>Confirm Payment</h1>
            )}
            {/* Device added to account at this point */}
            {stage === 2 && (
                <h1>Create Asset (Optional)</h1>
            )}
            {stage === 3 && (
                <>
                    <h1>Success!</h1>
                    <Typography variant='body1'>Your Hubo has been added to your account. At this point, you can:</Typography>
                    <ul>
                        <Link onClick={() => {advanceStage(0)}}><li>Add another Hubo</li></Link>
                        <Link to="/create-asset"><li>Create an Asset for your Hubo</li></Link>
                        <Link to="/assets"><li>View all your Assets and Hubos</li></Link>
                    </ul>
                    
                </>

            )}
            {stage === -1 && (
                <>
                    <Typography variant='h6'>Error</Typography>
                    <Typography variant='body1'>An error occurred when adding your Hubo. Please check your activation code and try again.</Typography>
                    (props.error ? <Typography variant='body1'>{props.error}</Typography> : null)
                </>
            )}
        </>
    )
}

export default LinkDeviceFlowView;