import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    Button,
    Container,
} from '@mui/material';

import { stripePublishableKey } from '../../config/constants/stripe';

import {
    // CardElement,
    useStripe,
    PaymentElement,
    useElements,
    Elements,
} from '@stripe/react-stripe-js';
import { Navigate } from 'react-router-dom';
import httpClient from '../../httpClient';


const SubscriptionForm = (props) => {

    // Get the lookup key for the price from the previous page redirect.
    let location = useLocation();
    // const [clientSecret, setClientSecret] = useState(location.state.clientSecret);
    // const clientSecret = location.state.clientSecret;
    const clientSecret = props.clientSecret;
    // const [subscriptionId, setSubscriptionId] = useState(location.state.subscriptionId);
    const subscriptionId = location.state.subscriptionId;
    // const [name, setName] = useState(httpClient.getCurrentUser().name);
    const [messages, _setMessages] = useState('');
    const [paymentIntent, setPaymentIntent] = useState();

    // helper for displaying status messages.
    const setMessage = (message) => {
        _setMessages(`${messages}\n\n${message}`);
    }

    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements({
        clientSecret: clientSecret
    });


    // When the subscribe-form is submitted we do a few things:
    //
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        // const cardElement = elements.getElement(CardElement);

        // // Use card Element to tokenize payment details
        // let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        //     payment_method: {
        //         card: cardElement,
        //         billing_details: {
        //             name: name,
        //         }
        //     }
        // });

        // Trigger form validation and wallet collection
        const {error: submitError} = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setMessage(submitError.message);
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            clientSecret,
            confirmParams: {
                return_url: new URL('/orgmanage', window.location.origin).toString(),

            },
        });

        if (error) {
            // show error and collect new card details.
            setMessage(error.message);
            return;
        }
        // setPaymentIntent(paymentIntent);
    }

    if (paymentIntent && paymentIntent.status === 'succeeded') {
        // update org subscription status
        httpClient.post('/organization/', {
            subscriptionId: subscriptionId,
            subscriptionIsActive: true,
            subscriptionStatus: 'active',
        }).then((response) => {
            // show confirmation
            alert("Your subscription has been activated successfully.");
            // redirect to org manage page
            return <Navigate to={{ pathname: '/orgmanage' }} />
        }).catch((reason) => {
            console.log("Error updating subscription status:");
            console.log(reason);
            alert(
                "There was a problem updating the subscription status. " +
                "Please refresh the page and try again."
            );
        });
    }

    if (!elements | !stripe) {
        return <p>Loading...</p>
    }

    if (!clientSecret) {
        return <p>Loading...</p>
    }
    
    return (
        <Container>
            <form onSubmit={handleSubmit} id='payment-form'>
                {/* <label>
                    Full name
                    <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                </label> */}

                {/* <CardElement /> */}
                <PaymentElement />
                <Button variant='contained' type='submit' color='primary'>Submit</Button>

                <div>{messages}</div>
            </form>
        </Container>
    )
}

const Subscribe = (props) => {
    let location = useLocation();
    const [clientSecret, setClientSecret] = useState(location.state.clientSecret);

    
    if (!clientSecret || !stripePublishableKey) {
        return <p>Loading...</p>
    }
    const stripePromise = loadStripe(stripePublishableKey);

    return (
        <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
            <SubscriptionForm clientSecret={clientSecret} />
        </Elements> 
        // <EmbeddedCheckoutProvider
        //   stripe={stripePromise}
        //   options={{
        //     clientSecret: clientSecret,
        //     // subscriptionId: subscriptionId,
        //   }}
        // >
        //   <EmbeddedCheckout />
        // </EmbeddedCheckoutProvider>
    )

    // old placeholder
    // return (
    //   <>
    //     <h1>Subscribe</h1>

    //     <p>
    //       Try the successful test card: <span>4242424242424242</span>.
    //     </p>

    //     <p>
    //       Try the test card that requires SCA: <span>4000002500003155</span>.
    //     </p>

    //     <p>
    //       Use any <i>future</i> expiry date, CVC,5 digit postal code
    //     </p>

    //     <hr />

    //     <form onSubmit={handleSubmit}>
    //       <label>
    //         Full name
    //         <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
    //       </label>

    //       <CardElement />

    //       <button>
    //         Subscribe
    //       </button>

    //       <div>{messages}</div>
    //     </form>
    //   </>
    // )
}

export default Subscribe;