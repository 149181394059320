import React, { useEffect, useState } from 'react';
import { confirmAlert } from "react-confirm-alert";
import { useParams } from 'react-router-dom';
import {
    Button,
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Tab,
} from '@mui/material';
import {
    TabContext,
    TabList,
    TabPanel,
} from '@mui/lab';
import { Link } from 'react-router-dom';

// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';

import httpClient from '../httpClient';
import withRouter from '../withRouter';
import AssetDetailPanel from '../components/AssetDetailPanel';
import AssetInfoForm from '../components/AssetInfoForm';
import AlarmConfigurationForm from '../components/AlarmConfigurationForm';


const AssetDetailView = (props) => {
    const [asset, setAsset] = useState();
    const subscriptionActive = props.user?.organization ? props.user.organization.subscriptionIsActive : true;
    const [subscriptionInactive, setSubscriptionInactive] = useState(!subscriptionActive);
    const [revData, setRevData] = useState([]);
    const { assetId } = useParams();
    const [tabValue, setTabValue] = useState("1");

    const updateAsset = () => {
        httpClient.get(
            '/asset/' + props.params.assetId
        ).then(res => {
            setAsset(res.data.asset);
        }).catch(err => {
            if (err.status === 402) {
                setSubscriptionInactive(true);
            } else {
                console.log(err);
            }
        });
    }

    const updateRevs = () => {
        httpClient.get(
            '/asset/revdata/' + props.params.assetId
        ).then(res => {
            setSubscriptionInactive(false);
            setRevData(res.data.revData);
        }).catch(err => {
            if (err.status === 402) {
                setSubscriptionInactive(true);
            } else {
                console.log(err);
            }
        });
    }

    const updateAll = () => {
        updateAsset();
        updateRevs();
    }

    const zeroOdometerRequest = () => {
        confirmAlert({
            title: <h4>Confirm Reset</h4>,
            message: (
                <p>
                    Are you sure you wish to reset the odometer reading to zero? Lifetime
                    distance traveled will be retained, but the current value will be
                    lost. <strong>This cannot be undone!</strong>
                </p>
            ),
            buttons: [
                {
                    label: "Cancel",
                    onClick: () => { },
                },
                {
                    label: "Reset to Zero",
                    onClick: () => {
                        httpClient
                            .post(
                                "/asset/zero-mileage/" + asset._id,
                                {}
                            )
                            .then((response) => {
                                alert("The odometer was reset successfully!");
                                setAsset(response.data.asset);
                            })
                            .catch((reason) => {
                                alert(
                                    "There was an error resetting the odometer. Please contact support if the error persists."
                                );
                            });
                    },
                },
            ],
        });
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }

    useEffect(() => {
        httpClient.get('/asset/' + assetId).then((response) => {
            setAsset(response.data.asset);
        }).catch((reason) => {
            alert("Failed to get asset. See error in console.");
            console.log("Error getting asset:");
            console.log(reason);
        })
    }, [assetId]);

    useEffect(() => {
        httpClient.get('/asset/revdata/' + assetId).then((response) => {
            setRevData(response.data.revData);
        }).catch((reason) => {
            alert("Failed to get revdata. See error in console.");
            console.log("Error getting revdata:");
            console.log(reason);
        })
    }, [assetId]);

    return asset === undefined ? (
        <p>Loading...</p>
    ) : (
        // <Grid container>
        //     <Grid item>
        //         <div>Asset: {asset._id}</div>
        //         <ChangeLinkedHubo asset={asset} />

        //         <AssetDetailPanel asset={asset} revData={revData} onResetOdometer={zeroOdometerRequest} user={props.user} />
        //     </Grid>
        // </Grid>

        <>
            <Button sx={{ float: 'right' }} variant="contained" type="button" onClick={updateAll}>Refresh</Button>
            <h2>Detail View - {asset.name}</h2>
            <TabContext value={tabValue}>
                <Box>
                    <TabList onChange={handleTabChange} id='assetDataTab'>
                        <Tab label="Telemetry" value="1" />

                        <Tab label="Asset Info" value="2" />

                        {/* <Tab eventKey='alarms' title="Alert Config">
                            <AlarmConfigurationForm asset={asset} />
                        </Tab> */}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    {subscriptionInactive ?
                        <p>Subscription inactive. Please <Link to="/prices">click here</Link> to activate subscription.</p> :
                        <AssetDetailPanel asset={asset} revData={revData} onResetOdometer={zeroOdometerRequest} user={props.user} />
                    }
                </TabPanel>
                <TabPanel value="2">
                    <AssetInfoForm asset={asset} updateAll={updateAll} user={props.user} />
                </TabPanel>
            </TabContext>
        </>

    )
}

export default withRouter(AssetDetailView);
