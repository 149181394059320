import React from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
// import FormGroup from 'react-bootstrap/FormGroup';
// import Form from 'react-bootstrap/Form';
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider,
  Input,
  Typography,
} from "@mui/material";

import DeviceAccessRequestsForm from './DeviceAccessRequestsForm';

import httpClient from "../httpClient";
import { baseURL } from "../config/constants/url";
import { diameterToRevsPerMile, revsPerMileToDiameter } from "../utils";

function ValidateForm(values) {
  let errors = {};

  // if(!values.email)
  //   errors.email = "Email Address Required";

  //check if my values have errors
  return errors;
}

function SetPostIntervalDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [device, setDevice] = React.useState(props.device);
  // const [interval, setInterval] = React.useState((props.device.postInterval) ? props.device.postInterval : 60);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const initialProps = props;
  const submitPostInterval = (values) => {
    httpClient
      .post(
        new URL("/device/post-interval/" + device.deviceID, baseURL),
        values
      )
      .then((response) => {
        alert(response.statusText);
        setDevice({...response.data.device});
        // setSubmitting(false);
        setOpen(false);
        return;
      })
      .catch((reason) => {
        alert(reason);
        // setSubmitting(false);
        setOpen(false);
        return;
      });
  };

  return (
    <>
      <Typography variant="body1" paragraph>Current interval set in cloud: {device.postIntervalMinutes}</Typography>
      <Typography variant="body1" paragraph>Time config was set in the cloud: {device.postIntervalChangeDate}</Typography>
      <Typography variant="body1" paragraph>Config has been acknowledged by device: {String(device.postIntervalConfirmed)}</Typography>
      <Typography variant="body1" paragraph>Latest interval reported by device: {device.deviceReportedInterval}</Typography>
      <Typography variant="body1" paragraph>Time config was reported by device: {device.postIntervalConfirmDate}</Typography>
      <Button variant="contained" onClick={handleClickOpen} type="button">
        Adjust Device Post Interval
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Set Posting Interval (minutes)</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            interval: device.postIntervalMinutes
              ? device.postIntervalMinutes
              : 60,
          }}
          onSubmit={submitPostInterval}
        >
          {({ values, setFieldValue, handleBlur }) => (
            <Form>
              <DialogContent>
                <DialogContentText>
                  Set a new post interval for the device. Note that the value
                  shown here is that which has been stored by the cloud; the
                  device may or may not have updated the setting yet.
                </DialogContentText>
                {/* Setting slider */}
                <Slider
                  // component={Slider}
                  name="interval"
                  aria-label="Post Interval"
                  value={values.interval}
                  valueLabelDisplay="on"
                  step={1}
                  marks
                  min={15}
                  max={60}
                  onChange={(event, value) => setFieldValue("interval", value)} // needed for slider with formik
                  onBlur={handleBlur}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Submit</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default class DeviceAssetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: props.device
    }
  }

  handleSubmit = (values, { props = this.props, setSubmitting }) => {
    delete values.deviceID;
    delete values.tireDiameter;
    httpClient
      .put(new URL("/device/" + this.state.device.deviceID, baseURL), values)
      .then((response) => {
        alert(response.statusText);
        setSubmitting(false);
        return;
      })
      .catch((reason) => {
        alert(reason);
        setSubmitting(false);
        return;
      });
    // Alert probably not needed
    // alert(JSON.stringify(values, null, 2));
  };

  zeroOdometerRequest = () => {
    confirmAlert({
      title: <h4>Confirm Reset</h4>,
      message: (
        <p>
          Are you sure you wish to reset the odometer reading to zero? Lifetime
          distance traveled will be retained, but the current value will be
          lost. <strong>This cannot be undone!</strong>
        </p>
      ),
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Reset to Zero",
          onClick: () => {
            httpClient
              .post(
                new URL(
                  "/device/zero-mileage/" + this.state.device.deviceID,
                  baseURL
                ),
                {}
              )
              .then((response) => {
                alert("The odometer was reset successfully!");
                this.setState({device: {...response.data.device}});
              })
              .catch((reason) => {
                alert(
                  "There was an error resetting the odometer. Please contact support if the error persists."
                );
              });
          },
        },
      ],
    });
  };

  render() {
    const device = this.state.device;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          deviceID: device.deviceID,
          name: device.name,
          description: device.description,
          // maintType: '',
          // maintMileage: '',
          // maintTime: '',
          revsPerMile: device.revsPerMile,
          tireDiameter: revsPerMileToDiameter(device.revsPerMile),
        }}
        validate={ValidateForm}
        onSubmit={this.handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <br />
            <h4>Attributes</h4>
            <FormGroup>
              <FormLabel>Device ID</FormLabel>
              <FormControl
                type="text"
                name="deviceID"
                placeholder="Device ID"
                disabled
                value={values.deviceID}
              />
              <ErrorMessage name="deviceID" />
            </FormGroup>

            <FormGroup>
              <FormLabel>Asset Name</FormLabel>
              <FormControl
                type="text"
                name="name"
                placeholder="Asset Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <ErrorMessage name="name" />
            </FormGroup>

            <FormGroup>
              <FormLabel>Asset Description</FormLabel>
              <FormControl
                type="text"
                name="description"
                placeholder="Asset Description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
              <ErrorMessage name="description" />
            </FormGroup>

            <FormGroup>
              <FormLabel>Revolutions per Mile</FormLabel>
              <FormControl
                type="number"
                name="revsPerMile"
                placeholder="Revolutions per Mile"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "tireDiameter",
                    revsPerMileToDiameter(Number(e.target.value))
                  );
                }}
                onBlur={handleBlur}
                value={values.revsPerMile}
              />
              <ErrorMessage name="revsPerMile" />
            </FormGroup>

            <FormGroup>
              <FormLabel>Tire Diameter (inches)</FormLabel>
              <FormControl
                type="number"
                name="tireDiameter"
                placeholder="Tire Diameter"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "revsPerMile",
                    diameterToRevsPerMile(Number(e.target.value))
                  );
                }}
                onBlur={handleBlur}
                value={values.tireDiameter}
              />
              <ErrorMessage name="tireDiameter" />
            </FormGroup>
            {/* <Form.Control    // select example
              name="gender"
              component="select"
              placeholder="Your Gender">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
            </Form.Control> */}

            {/* <ErrorMessage name="gender" /> */}
            <div>
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                Save
              </Button>
            </div>
            <br />
            {(this.props.user?.admin) ? <>
              <h4>Post Interval Configuration</h4>
              <Box>
                <SetPostIntervalDialog device={device} />
              </Box>
              <br></br>
            </> : ''}
            <h4>Actions</h4>
            <Box>
              <Button
                variant="contained"
                onClick={this.zeroOdometerRequest}
                type="button"
              >
                Reset Odometer to Zero
              </Button>
            </Box>
            <br></br>
            {/* Don't need to ObjectId compare here since they're strings at this point */}
            {(this.props.user._id === device.user) ? <>
              <h4>Device Access Requests</h4>
              <DeviceAccessRequestsForm user={this.props.user} device={this.device} />
            </> : ''
            }
            
            
          </Form>
        )}
      </Formik>
    );
  }
}
