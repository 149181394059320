import React from 'react';
import {
    Box,
    Button,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';

import { Link } from 'react-router-dom';

import AssetList from '../components/AssetList';
import HuboList from '../components/HuboList';

import httpClient from '../httpClient';

// Used example provided by https://mui.com/material-ui/react-tabs/
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}


const AssetListView = (props) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [assets, setAssets] = React.useState([]);
    const [devices, setDevices] = React.useState([]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    React.useEffect(() => {
        const org = props.user.organization;
        httpClient.get(
            '/organization/assets/' + org._id       
        ).then((response) => {
            setAssets(response.data.assets);
        }).catch((reason) => {
            console.log("Error getting assets:");
            console.log(reason);
        });
        httpClient.get(
            '/organization/devices/' + org._id  
        ).then((response) => {
            setDevices(response.data.devices);
        }).catch((reason) => {
            console.log("Error getting assets:");
            console.log(reason);
        });
    }, []);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label="Assets" value={0} />
                    <Tab label="Hubos" value={1} />
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                <Box sx={{marginTop: 2}}>
                    <Button variant='contained' color='primary' component={Link} to="/create-asset">Create New Asset...</Button>
                </Box>
                <hr/>
                <>
                { props.user.organization?.subscriptionInactive ? 
                        <p>Subscription inactive. Please <Link to="/prices">click here</Link> to activate subscription.</p> : 
                    <AssetList assets={assets} user={props.user}/>
                }
                </>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <HuboList devices={devices} user={props.user}/>
            </CustomTabPanel>
        </>
    )
}

export default AssetListView;