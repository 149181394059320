const devURL = 'http://localhost:3001';
const prodApiServerUrl = 'https://beta-api.tracmile.com/';
// const prodURL = process.env.API_URL ? process.env.API_URL : 'http://hermesapi.us-east-1.elasticbeanstalk.com/';
let prodURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : prodApiServerUrl;

// hack to get around the fact that we build once for both beta and live
if (window.location.hostname === 'tracmile.com') {
    prodURL = 'https://api.tracmile.com/';
}

export const baseURL = process.env.NODE_ENV === 'development' ? devURL : prodURL;