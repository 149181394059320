import React, {useState, useEffect} from 'react';

import httpClient from "../httpClient";
import { baseURL } from "../config/constants/url";

import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';

function DeviceAccessRequestElement(props) {
    
    const approveRequest = () => {
        httpClient.put(
            new URL('/user-roles/request/response/' + props.accessRequest._id, baseURL),
            {status: 1} // approve
        ).then(props.updateAccessRequests)
         .catch((reason) => {
            console.log("Error when approving access request:");
            console.log(reason);
         })
    }
    const rejectRequest = () => {
        httpClient.put(
            new URL('/user-roles/request/response/' + props.accessRequest._id, baseURL),
            {status: -1} // deny
        ).then(props.updateAccessRequests)
         .catch((reason) => {
            console.log("Error when approving access request:");
            console.log(reason);
         })
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Device Access Request</Typography>
                <Typography variant="body1">{props.accessRequest.message}</Typography>

            </CardContent>
            <CardActions>
                <Button variant="contained" type="button" onClick={approveRequest}>Approve</Button>
                <Button variant="contained" type="button" onClick={rejectRequest}>Reject</Button>
            </CardActions>
        </Card>
    )
}

export default function DeviceAccessRequestsForm(props) {
    const [accessRequests, setAccessRequests] = useState([]);

    const updateAccessRequests = () => {
        httpClient.get(
            new URL('/user-roles/request/list-by-receiver/' + props.user._id, baseURL)
        ).then((response) => {
            setAccessRequests(response.data.result)
        }).catch((reason) => {
            console.error("Error when retrieving access requests:");
            console.error(reason);
        })
    }

    useEffect(() => {
        updateAccessRequests();
    }, []);

    return (
        <>
            {accessRequests.map((accessRequest) => (
                accessRequest.visibility ? 
                    <DeviceAccessRequestElement 
                        updateAccessRequests={updateAccessRequests} 
                        key={accessRequest._id} 
                        accessRequest={accessRequest}
                    /> : ''
            ))}
        </>
    )
}
