import React, { Component } from 'react';

// import Grid from "@material-ui/core/Grid";
import {FormGroup, FormControlLabel, Checkbox, Grid} from '@mui/material';
import TextField from "@mui/material/TextField";

import { Formik, Field, Form } from 'formik';
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import Radio from "@material-ui/core/Radio";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import Slider from "@material-ui/core/Slider";
import Button from "@mui/material/Button";

export default class MyProfileForm extends Component {

  render() {
    const initialValues = {
      email: this.props.user.email,
      phone: this.props.user.mobileNumber
    }
    return (
      <Formik
        initialValues={initialValues}

      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting
        }) => (
          <Form>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="email-input"
                name="email"
                label="Email Address"
                type="text"
                value={values.email}
                // onChange={handleChange}
                // onBlur={handleBlur}
              />
              {/* <div>
                <br></br>
                <a href='foo'>Change email address</a>
              </div> 
              <br></br> */}
            </Grid>
  
            <Grid item xs={12}>
              <TextField
                id="phone-input"
                name="phone"
                label="Mobile Number"
                type="text"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Receive Email Alerts" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Receive SMS Alerts" />
              </FormGroup>
            </Grid>
          </Grid>
          <br></br>
          <Button type="button" variant="contained">Save</Button>
        </Form>
        )}
      </Formik>

    )
  }
}