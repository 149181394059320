import React from 'react'
import httpClient from '../httpClient'
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

import withRouter from '../withRouter';
import { ErrorMessage } from 'formik';
import { baseURL } from '../config/constants/url';


// sign up form behaves almost identically to log in form. We could create a flexible Form component to use for both actions, but for now we'll separate the two:
class OrgUserSignupMobile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      email: '',
      password: '',
      passwordConfirm: '',
      fullName: '',
      errorStatus:"",
      errorMessage:"",
      token:null,
      user:null,
      loading:false,
      accountCreated:false,
    };
  }

  handleSubmit = () => {
    const fullName = this.state.fullName.trim();
    const password = this.state.password.trim();
    const passwordConfirm = this.state.passwordConfirm.trim();
    const user = this.state.user;
    const token = this.state.token;

    if(fullName == ""){
      this.setState({errorStatus:"errorMsg",errorMessage:"Error: Full name is required."});
    }else if(password == ""){
      this.setState({errorStatus:"errorMsg",errorMessage:"Error: Password is required."});
    }else if(passwordConfirm == ""){
      this.setState({errorStatus:"errorMsg",errorMessage:"Error: Password confirmation is required."});
    }else if(password !== passwordConfirm){
      this.setState({errorStatus:"errorMsg",errorMessage:"Error: Passwords do not match. Please ensure both passwords are identical."});
    }else{
      this.setState({loading:true});
      const payload = {
        userId:user?._id,
        username: fullName,
        // email: user?.email,
        password:password,
        fullName: fullName,
        isVerified: true,
    };


    console.log("payload: ",payload);
    console.log("token: ",token);
    console.log("userId: ",user?._id);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    console.log("base url: ",baseURL);

    axios({
      method: 'post',
      baseURL: baseURL,
      url: `/organization/setup-new-user`,
      data: payload,
      headers: headers
    }).then((result) => {
        if(result?.status == 200){
          console.log("result: ",result);
          this.setState({loading:false,accountCreated:true});
        }else{
          console.log("error: ",result);
          this.setState({loading:false,accountCreated:false});
        }
      })
      .catch((reason) => {
        this.setState({loading:false});
        console.log("error: ",reason);
      });
    }
    
    
  };

  componentDidMount() {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('data');
      console.log('token: ', token);
      const user = JSON.parse(atob(token.split('.')[1]));
      console.log(user) // bar
      this.setState({  
        token: token, 
        user: user,
        email: user?.email 
      });
    } catch (error) {
      console.error("error: "+error.message);
    }
  }

  __renderUrlErrorView = () => {
    return(
      <div>

      </div>
    );
  }

  __renderSetupAccountView = (user,token) =>{
    return (
      <div>
        <Card className="container" style={{ marginBottom: 10, padding: 16 }}>
          <b><h5>{user?.organization?.name}</h5></b>

          <FormLabel>6960 N sheredin RD</FormLabel>
          <FormLabel>E-mail: {user?.organization?.primaryEmail}</FormLabel>
        </Card>

        <Card className="container" style={{ padding: 16 }}>
          <div>
            <h4>Setup your account</h4>
            <hr style={{marginTop: "1rem",marginBottom: "1rem",border: 0,borderTop: "1px solid rgba(0, 0, 0, 0.1)"}}/>

            {this.state.errorStatus == 'errorMsg' ? (
              <Alert key={"danger"} variant='danger' style={{  marginBottom:10 }}>
              {this.state.errorMessage}
            </Alert>
            ) : null}

            <FormGroup controlId="email">
              <FormLabel>Email Address</FormLabel>
              <InputGroup className="mb-3">
                {' '}
                <FormControl
                  type="text"
                  value={this.state.email}
                  name="email"
                  disabled
                />
                <InputGroup.Text id="basic-addon2">
                  <CheckCircleIcon fontSize="20" color="success" />
                </InputGroup.Text>
              </InputGroup>
            </FormGroup>

            <FormGroup controlId="username" style={{ marginTop: 10 }}>
              <FormLabel>Full Name</FormLabel>
              <FormControl
                type="text"
                value={this.state.fullName}
                name="username"
                onChange={(event) => {
                  this.setState({ fullName: event.target.value });
                }}
                ic
              />
            </FormGroup>
            {this.state.errorStatus == 'fullName' ? (
              <FormText id="passwordHelpBlock" style={{ color: 'red' }}>
                
              </FormText>
            ) : null}

            <FormGroup controlId="password" style={{ marginTop: 10 }}>
              <FormLabel>Password</FormLabel>
              <FormControl
                type="password"
                value={this.state.password}
                name="password"
                onChange={(event) => {
                  this.setState({ password: event.target.value });
                }}
                // placeholder="Password"
              />
            </FormGroup>
            {this.state.errorStatus == 'password' ? (
              <FormText id="passwordHelpBlock" style={{ color: 'red' }}>
                
              </FormText>
            ) : null}

            <FormGroup controlId="passwordConfirm" style={{ marginTop: 10 }}>
              <FormLabel>Confirm Password</FormLabel>
              <FormControl
                type="password"
                value={this.state.passwordConfirm}
                name="passwordConfirm"
                onChange={(event) => {
                  this.setState({ passwordConfirm: event.target.value });
                }}
                // placeholder="Confirm Password"
              />
            </FormGroup>
            {this.state.errorStatus == 'passwordConfirm' ? (
              <FormText id="passwordHelpBlock" style={{ color: 'red' }}>
                
              </FormText>
            ) : null}

            

            <Button
              variant="primary"
              type="submit"
              onClick={() => {
                this.handleSubmit();
              }}
              style={{ marginTop: 10, width: '100%' }}
            >
              Sign Up
            </Button>

            {/* <FormText>
              Already have an account? <Link to={'/login'}>Log in here</Link>.
            </FormText> */}
          </div>
        </Card>
      </div>
    );
  }

  __renderCompleteRegisterationView = (user,token) =>{
    return (
      <div>
        <Card className="container" style={{ marginBottom: 10, padding: 16 }}>
          <h6>
            Your account is created sucessfully. You can login in website or app
          </h6>
          

          <Container>
            <Row>
              <Col>
              <a href='https://play.google.com/store/apps/details?id=com.hermesprototype&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width={"100%"}/>
                </a>
              </Col>
              <Col>
              <a href='https://play.google.com/store/apps/details?id=com.hermesprototype&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width={"100%"}/>
                </a>
              </Col>
            </Row>
          </Container>

          <FormText style={{marginTop:15}}>
            Already have an account? <Link to={'/login'}>Log in here</Link>.
          </FormText>
        </Card>
      </div>
    );
  }

  render() {
    const user = this.state.user;
    const token = this.state.token;
    const accountCreated = this.state.accountCreated;
   return (
     <div>
       {user && token && !accountCreated
         ? this.__renderSetupAccountView(user, token)
         : this.__renderUrlErrorView(user, token)}
         {
          user && token && accountCreated ? this.__renderCompleteRegisterationView(user,token) : null
         }
     </div>
   );
  }
}

export default withRouter(OrgUserSignupMobile);
