
import React, { Component } from 'react';
import { Button, TextField, Grid, Container } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import httpClient from '../httpClient';
import { baseURL } from '../config/constants/url';

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password too short (minimum 8 characters).')
    .max(50, 'Password too long (maximum 50 characters).')
    .required('Please enter a new password.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('You must confirm your new password.'),
  email: Yup.string()
    .email('Please enter the email address associated with your account.')
    .required('Please enter the email address associated with your account.')
});

class ForgotPasswordForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      passwordChanged: false
    }
  }

  handleSubmit = (values, {
    props = this.props,
    setSubmitting
  }) => {

    // validate
    if (values.password === undefined || values.password === "") {
      alert("You must enter a new password.")
    }

    setSubmitting(true);
    httpClient.post(
      new URL('/user/verify-forget-password', baseURL),
      values
    ).then(res => {
      setSubmitting(false);
      if (res && res.data.tokenVerified) {
        this.setState({
          passwordChanged: true
          // revData: res.data.revData,
        });
      } else {
        alert("There was an error resetting your password; please try again. If you keep getting this message, please contact support.")
      }

    }).catch(reason => {
      alert("There was an error resetting your password; please try again. If you keep getting this message, please contact support.")
      console.log(reason);
      setSubmitting(false);
    });
  }

  render() {

    const queryEmail = new URLSearchParams(window.location.search).get("email");
    const token = new URLSearchParams(window.location.search).get('t');

    if (this.state.passwordChanged) {
      return (
        <>
          <p>Your password has been changed successfully!</p>
          <Link to="/login">
            <Button variant="contained">Return to Login</Button>
          </Link>
        </>
      )

    } else {
      return (
        <>
          <Container>
            <Formik
              initialValues={{
                email: queryEmail,
                password: '',
                confirmPassword: '',
                token: token
              }}
              validationSchema={PasswordSchema}
              onSubmit={this.handleSubmit}
            >
              {({
                isSubmitting,
                values,
                errors,
                touched,
                handleSubmit,
                handleBlur,
                handleChange
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <h2>Change Password</h2>
                    {/* <label htmlFor='email'>Email Address</label> */}
                    <Grid item xs={12}>
                      <TextField
                        id="email"
                        name="email"
                        label="Email Address"
                        error={Boolean(errors.email && touched.email)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        helperText={touched.email && errors.email}
                        required
                        type="email"
                      />
                    </Grid>



                    {/* <label htmlFor="password">Enter New Password</label> */}
                    <Grid item xs={12}>
                      <TextField
                        id="password"
                        name="password"
                        label="New Password"
                        error={Boolean(errors.password && touched.password)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        helperText={touched.password && errors.password}
                        required
                        type="password"
                        autoComplete="off"
                      />
                    </Grid>

                    {/* <label htmlFor="confirmPassword">Confirm New Password</label> */}
                    <Grid item xs={12}>
                      <TextField
                        id="confirmPassword"
                        name="confirmPassword"
                        label="Confirm New Password"
                        error={Boolean(errors.confirmPassword && touched.confirmPassword)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        required
                        type="password"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item>

                      <Button variant="contained" disabled={isSubmitting} type="submit">Submit</Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Container>
        </>
      )
    }
  }
}

export default ForgotPasswordForm;
