import React, {Component} from 'react';
// import Highcharts from 'highcharts';
import Highcharts from 'highcharts';
// import {
//   HighchartsChart, Chart, HighchartsProvider, XAxis, YAxis, Title, Subtitle, Legend, ColumnSeries, Tooltip, Debug
// } from 'react-jsx-highcharts';

import HighchartsReact from 'highcharts-react-official';

function prepareChartData(revsData, device) {
  // let chartData = [];
  let categoryData = [];
  let columnChartData = [];
  let revsPerMile = device.revsPerMile;

  // const getMileage = function(revs, device) {
  //   if (device === undefined) {
  //     return -1;
  //   }
  //   if (device.revsPerMile === undefined) {
  //     return -1;
  //   }
  //   let offset = device.revsResetOffset;
  //   if (!offset) {
  //     offset = 0;
  //   }
  //   return (revs - offset) * 1.0 / device.revsPerMile;
  // }

  const processMonthlyMileage = (revData, device) => {
    // Assumes sorted by timestamp
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let months = {};
    for (let i = 0; i < revsData.length; i++) {
      let date = new Date(revsData[i].ts);
      let month = monthNames[date.getMonth()] + ' ' + date.getFullYear();
      if (months[month] === undefined) {
        months[month] = {
          start: revsData[i].revs,
          end: revsData[i].revs
        };
      } else {
        // month data already exists
        months[month].end = revsData[i].revs;
      }
    }
    return months;
  }

  let monthData = processMonthlyMileage(revsData, device);

  
  for (let i = 0; i < Object.entries(monthData).length; i++) {
    let [monthName, monthRevBounds] = Object.entries(monthData)[i];

    categoryData.push(monthName);
  
    columnChartData.push([
      monthName,
      Math.max((monthRevBounds.end - monthRevBounds.start) * 1.0 / device.revsPerMile, 0)
    ]);
  }

  // for (let i = 0; i < revsData.length; i++) {
  //   // const date = revsData[i].ts;
  //   // console.log(date);
  //   // chartData.push([
  //   //   date,
  //   //   getMileage(revsData[i], device)
  //   // ]);
  // }
  // chartData.sort(function(a, b) {
  //   return a[0] - b[0];
  // })
  return {
    categories: categoryData,
    data: columnChartData
  };
}

class MileageChart extends Component {
  // constructor(props) {
  //   super(props);
  //   // this.Highcharts = useHighcharts();
  //   this.state = {
  //     revsData: this.props.revData
  //   }
  // }

  // componentDidUpdate() {
  //   console.log("Chart updated");
  // }

  // componentWillUnmount() {
  //   if (this.chartRef) {
  //     this.chartRef.destroy();
  //     this.chartRef = null;
  //   }
  // }

  render() {
    let title = "Monthly Mileage";
    if (this.props.title) {
      title = this.props.title;
    }
    const tooltipFormatter = function() {
      // return new Date(this.x) + ', miles: ' + this.y;
      return this.x + ': ' + this.y.toFixed(1) + ' mi';
    }
    const data = prepareChartData(this.props.revData, this.props.device);
    let plotOptions = {
      chart: {
        type: 'column',
        zoomType: 'x',
        height: 180
      },
      credits: {
        enabled: false
      },
      series: [{
        data: data.data
      }],
      title: {
        // text: 'Historical Distance Data'
        text: title
      },
      legend: {
        enabled: false
      },
      tooltip: {
        shared: true,
        enabled: true,
        formatter: tooltipFormatter
      },
      xAxis: [{
        title: {text: 'Date / Time'},
        // type: 'datetime'
        categories: data.categories
      }],
      yAxis: [
        {
          title: {text: 'Mileage'},
          // title: { text: 'Distance (mi)' },
        },
        // {
        //   title: {text: 'Revolutions'},
        //   opposite: true,
        // }
      ],
      // time: {
      //   useUTC: false,
      // },
    };
    
    return (
      <div className='mileage-chart-container'>
        <HighchartsReact 
          highcharts={Highcharts}
          options={plotOptions}
        />
        {/* <HighchartsProvider Highcharts={Highcharts}>
          <HighchartsChart plotOptions={plotOptions}>
            <Chart height={180} onLoad={(chart) => {
              this.chartRef = chart;
            }}/>
            
            <Title>{title}</Title>

            //<Legend layout="vertical" align="right" verticalAlign="middle" /> 
            <Tooltip shared={true} formatter={tooltipFormatter}/>
            <XAxis categories={data.categories}>
              //* <XAxis.Title>Date / Time</XAxis.Title> 
            </XAxis>

            <YAxis min={0}>
              <YAxis.Title>Mileage</YAxis.Title>
              <ColumnSeries name="Monthly Mileage" data={data.seriesData} color="#1462ac"/>
            </YAxis>

            <Debug /> 
          </HighchartsChart>
        </HighchartsProvider> */}
      </div>
    );
  }
}

export default MileageChart;
