import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { PaymentElement } from '@stripe/react-stripe-js';

const AddPaymentMethod = () => {
    
    return (
        <>
            <form>
                <PaymentElement />
                <Button type='submit' variant="contained">Submit</Button>
            </form>
        </>
    )
}

export default AddPaymentMethod;
