import React, { useEffect, useState } from 'react';

import { Box, Button, Checkbox, FormGroup, FormControlLabel, TextField } from '@mui/material';
import { useFormik } from 'formik';

import httpClient from '../httpClient';

const AlarmConfigurationForm = (props) => {

    const asset = props.asset;
    const [alertSetting, setAlertSetting] = useState();

    useEffect(() => {
        httpClient.get('/alertSetting/asset/' + asset._id).then(res => {
            setAlertSetting(res.data.alertSetting);
        });
    }, [asset]);

    const formik = useFormik({
        initialValues: {
            assetId: asset._id,
            alertTypes: [],
            alertMedium: {
                email: false,
                sms: false,
                pushNtf: false,
            },
            speed: {
                enabled: false,
            },
            temperature: {
                enabled: false,
            },
            maintenance: {
                timeBase: {
                    enabled: false,
                },
                mileageBase: {
                    enabled: false,
                },
            },
        },
    });

    return (
        <>
            <Box>
                <form onSubmit={formik.handleSubmit}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                id="speed"
                                name="speed"
                                value={formik.values.speed.enabled}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />}
                            label="Speed Threshold Alarm"
                        />
                        <TextField
                            id="speed"
                            name="speed"
                            label="Speed"
                            type="number"
                            value={formik.values.speed}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FormGroup>
                    <TextField
                        id="temperature"
                        name="temperature"
                        label="Temperature"
                        type="number"
                        value={formik.values.temperature}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <TextField
                        id="maintenance"
                        name="maintenance"
                        label="Maintenance"
                        type="number"
                        value={formik.values.maintenance}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <Button type="submit">Submit</Button>
                </form>
            </Box>

        </>
    )
}

export default AlarmConfigurationForm;