import React from 'react';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';


const VerifyEmailForm = () => {

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: values => {
            console.log(values);
        }
    });

    return (
        <div>VerifyEmailForm</div>
    )
}

export default VerifyEmailForm;