import React, { Component } from 'react';

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';

import { Link } from 'react-router-dom';

import withRouter from '../withRouter';
import httpClient from '../httpClient';

const defaultFields = {
  email: '',
  password: '',
  // fullName: '',
};

class LogIn extends Component {
  state = {
    errorMessage: undefined
  };

  onFormSubmit = (values, { setSubmitting }) => {
    httpClient.logIn(values).then((user) => {
      console.log('Login call done');
      if (user) {
        this.props.onLoginSuccess(user);
        // this.props.history.push('/devices');
        if (window.location.search.indexOf('redirectTo') >= 0) {
          return this.props.navigate(this.props.searchParams.get('redirectTo'));
        } else {
          return this.props.navigate('/assets');
        }
      } else {
        this.setState({ errorMessage: "Incorrect email or password" })
        setSubmitting(false);
      }
    }).catch(reason => {
      console.log("Error when submitting login:");
      console.log(reason);
      this.setState({ errorMessage: "Login request failed; please try again later." })
      setSubmitting(false);
    });
  }

  render() {
    return (
      <Formik initialValues={defaultFields} onSubmit={this.onFormSubmit}>
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >

            <Typography component="h1" variant="h5">
              Sign in to TracMile<small><sup>&trade;</sup></small>
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              {/* <Container> */}

              <TextField
                margin="normal"
                fullWidth
                autoComplete="email"
                autoFocus
                id="email"
                name="email"
                label="Email Address"
                error={Boolean(errors.email && touched.email)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                helperText={touched.email && errors.email}
                required
                type="email"
              />

              <TextField
                margin="normal"
                fullWidth
                autoComplete="current-password"
                id="password"
                name="password"
                label="Password"
                error={Boolean(errors.password && touched.password)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                helperText={touched.password && errors.password}
                required
                type="password"
              />

              <Button
                variant="contained"
                fullWidth
                disabled={isSubmitting}
                type="submit"
                sx={{
                  mt: 3,
                  mb: 2
                }}
              >
                Sign in
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to={'/forgotpassword'} variant="body2">
                    Forgot password?
                  </Link>
                  {/* <Typography variant="body2" gutterBottom>
                    Forgot your password?{' '}
                    <Link to={'/forgotpassword'}>
                      Click here to reset your password.
                    </Link>
                  </Typography> */}
                </Grid>
                <Grid item xs>
                  <Link to={'/orgsignup'} variant="body2">
                    Don't have an account? Sign Up
                  </Link>

                  {/* <Typography variant="body2" gutterBottom>
                    Don't have an account?{' '}
                    <Link to={'/signup'}>Create one here</Link>.
                  </Typography> */}
                </Grid>
                <Grid item xs={12}>
                  {(this.state.errorMessage !== undefined) ? (
                    <FormHelperText error id="error-message-text">
                      {this.state.errorMessage}
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Formik>
    );
  }
}

export default withRouter(LogIn);
