import React from 'react';
import {
    Routes,
    Route,
    // Link,
    Navigate,
    // withRouter
} from 'react-router-dom';

import DeviceList from './panels/devicelist';
// import TopBanner from './panels/topbanner';
import HomePage from './containers/HomePage';
import LogIn from './views/LogIn';
import LogOut from './views/LogOut';
import SignUp from './views/SignUp';
import UserManage from './containers/UserManage';
import UserDetail from './containers/UserDetail';
import ManageFirmwareView from './views/ManageFirmwareView';
import DeviceDetail from './containers/DeviceDetail';
import MainDashboard from './containers/MainDashboard';
import LargeMapView from './containers/LargeMapView';
import TripHistoryView from './views/TripHistoryView';
import MyProfileView from './views/MyProfileView';
import SupportView from './views/SupportView';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ForgotPasswordRequestForm from './components/ForgotPasswordRequestForm';
import EmailConfirmationForm from './components/EmailConfirmationForm';
import MQTTLogView from './views/MQTTLogView';
import AccountDeleteView from './views/AccountDeleteView';
import ConfigureTireSizeView from './views/ConfigureTireSizeView';
import AssetListView from './views/AssetListView';
import AssetDetailView from './views/AssetDetailView';
import AssetTripHistoryView from './views/AssetTripHistoryView';
import CreateAssetForm from './components/CreateAssetForm';
import LinkDeviceView from './views/LinkDeviceView';
import LinkDeviceFlowView from './views/LinkDeviceFlowView';
import OrganizationManageView from './views/OrganizationManageView';
import OrganizationSignupView from './views/OrganizationSignupView';
import InviteUserView from './views/InviteUserView';
import VerifyEmailForm from './components/VerifyEmailForm';
import PricingForm from './components/subscription/PricingForm';
import SubscriptionCheckoutForm from './components/subscription/SubscriptionCheckoutForm';
import AddPaymentMethod from './components/subscription/AddPaymentMethod';
import SubscriptionCancelView from './views/SubscriptionCancelView';
import OrgUserSignupMobile from './views/OrgUserSignupMobile';
import VerifyEmailView from './views/VerifyEmailView';

// Helper components; TODO: move to a separate file
const ForgotPasswordHelper = (props) => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const token = params.get('t');
    const email = params.get('email');
    if (!token || !email) {
        return (
            <p>
                Invalid forgot password link. Please use the link in the email
                that was sent.
            </p>
        );
    }
    return <ForgotPasswordForm token={token} email={email} />;
};

const EmailConfirmationHelper = (props) => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const token = params.get('t');
    const email = params.get('email');
    if (!props.currentUser) {
        const loginLinkWithRedirect = `/login?redirectTo=/confirm-email?t=${token}&email=${email}`;
        return (<Navigate to={loginLinkWithRedirect} />);
    }
    if (!token || !email) {
        return (
            <p>
                Invalid confirmation link. Please use the link in the email that
                was sent.
            </p>
        );
    }
    return <EmailConfirmationForm token={token} email={email} />;
};

const MainRoutes = (props) => {
    const {
        currentUser,
        onLoginSuccess,
        logOut,
        isAuthenticated
    } = props;
    return (
        <Routes>
            <Route
                path="/login"
                element={<LogIn onLoginSuccess={onLoginSuccess} />}
            ></Route>

            <Route
                path="/logout"
                element={<LogOut onLogOut={logOut} />}
            ></Route>

            {/* the sign up component takes an 'onSignUpSuccess' prop which will perform the same thing as onLoginSuccess: set the state to contain the currentUser */}
            <Route
                path="/signup"
                element={<SignUp onSignUpSuccess={onLoginSuccess} />}
            ></Route>

            <Route
                path="/org_user_signup"
                element={<OrgUserSignupMobile />}
            ></Route>

            <Route
                path="/dashboard"
                element={
                    currentUser ? (
                        // ? <DashboardContainer />
                        <MainDashboard user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/devicedetail/:deviceID"
                element={
                    currentUser ? ( // TODO: Device access control
                        <DeviceDetail user={currentUser} />
                    ) : (
                        <Navigate to={`/login?redirectTo=/dashboard`} />
                    )
                }
            ></Route>

            <Route
                path="/usermanage"
                element={
                    currentUser?.admin ? (
                        <UserManage user={currentUser} />
                    ) : (
                        <Navigate to="/login?redirectTo=/usermanage" />
                    )
                }
            ></Route>
            <Route
                path="/userdetail/:userID"
                element={currentUser?.admin ? <UserDetail /> : <Navigate to="/login" />}
            ></Route>

            <Route
                path="/managefirmware"
                element={
                    currentUser?.admin ? ( // TODO: SECURITY!
                        <ManageFirmwareView />
                    ) : (
                        <Navigate to="/" />
                    )
                }
            ></Route>

            <Route
                path="/mqttlog"
                element={
                    currentUser?.admin ? ( // TODO: SECURITY!
                        <MQTTLogView />
                    ) : (
                        <Navigate to="/" />
                    )
                }
            ></Route>

            <Route
                path="/linkdevice"
                element={
                    currentUser ? (
                        <LinkDeviceView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/linkhubo"
                element={
                    currentUser ? (
                        <LinkDeviceFlowView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/devices"
                element={
                    currentUser ? (
                        <DeviceList user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/assets"
                element={
                    currentUser ? (
                        <AssetListView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/assetdetail/:assetId"
                element={
                    currentUser ? ( // TODO: Device access control
                        <AssetDetailView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/create-asset"
                element={
                    currentUser ? (
                        <CreateAssetForm user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/triphistory/:deviceID"
                element={
                    currentUser ? (
                        <TripHistoryView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/assettriphistory/:assetId"
                element={
                    currentUser ? (
                        <AssetTripHistoryView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/mapview"
                element={
                    currentUser ? (
                        <LargeMapView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/alerts"
                element={currentUser ? <MainDashboard /> : <Navigate to="/login" />}
            ></Route>

            <Route
                path="/support"
                element={currentUser ? <SupportView /> : <Navigate to="/login" />}
            ></Route>

            <Route
                path="/profile"
                element={
                    currentUser ? (
                        <MyProfileView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/orgmanage"
                element={
                    currentUser ? (
                        <OrganizationManageView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/orgsignup"
                element={
                    <OrganizationSignupView user={currentUser} />
                }
            ></Route>

            <Route
                path="/invite-user"
                element={
                    currentUser ? (
                        <InviteUserView user={currentUser} />
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            ></Route>

            <Route
                path="/tireconf"
                Component={() => {
                    const query = window.location.search;
                    const params = new URLSearchParams(query);
                    // const deviceID = params.get('device');
                    const assetId = params.get('asset');
                    const nextView = params.get('nextView');
                    if (!assetId) {
                        return <p>Invalid request</p>;
                    }
                    if (currentUser) {
                        return (
                            <ConfigureTireSizeView assetId={assetId} nextView={nextView} />
                        );
                    } else {
                        return <Navigate to="/login" replace />;
                    }
                }}
            ></Route>

            <Route
                path="/forgotpassword"
                element={<ForgotPasswordRequestForm />}
            ></Route>

            <Route
                path="/confirm-email"
                element={<VerifyEmailView />}
            ></Route>

            <Route
                path="/user/verifyEmailByLink/:verificationCode/:emailAddress"
                element={<VerifyEmailForm />}
            >
            </Route>

            <Route
                path="/forgot"
                element={<ForgotPasswordHelper />}
            ></Route>

            <Route path="/account-deletion" element={<AccountDeleteView />}></Route>

            <Route path='/prices' element={
                currentUser ? <PricingForm /> : <Navigate to="/login" />
            }></Route>

            <Route path='/subscribe' element={
                currentUser ? <SubscriptionCheckoutForm /> : <Navigate to="/login" />
            }></Route>

            <Route path="/subscription-cancel" element={
                currentUser ? <SubscriptionCancelView /> : <Navigate to="/login" />
            }></Route>

            <Route path="/add-payment-method" element={
                currentUser ? <AddPaymentMethod /> : <Navigate to="/login" />}
            ></Route>
            <Route
                path="/"
                element={currentUser ? <HomePage /> : <Navigate to="/login" />}
            ></Route>
        </Routes>
    );
};

export default MainRoutes;
