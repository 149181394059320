import React from 'react';

import { Button, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import httpClient from '../httpClient';

const SubscriptionCancelView = () => {

    const submitCancelRequest = () => {
        // submit request to cancel subscription
        httpClient.post(
            '/stripe/cancel-subscription'
        ).then((response) => {
            // show confirmation
            alert("Subscription canceled successfully.");
            // navigate to orgmanage
            window.location.href = '/orgmanage';
        }).catch((reason) => {
            console.log("Error canceling subscription:");
            console.log(reason);
            alert(
                "There was a problem canceling the subscription. " + 
                "Please refresh the page and try again."
            );
        });
    }

    return (
        <>
            <Paper elevation={3} sx={{padding: 2, margin: 2}}>
                <Typography variant='h4' gutterBottom>About to cancel subscription</Typography>
                <Typography variant='body1' gutterBottom>
                    Are you sure you want to cancel your subscription? You will no longer have access to your data.
                </Typography>

                <Button variant='contained' color='warning' sx={{margin: 1}} onClick={submitCancelRequest}>Cancel Subscription</Button>
                <Link to='/orgmanage' style={{textDecoration: 'none'}}>
                    <Button variant='contained' color='primary' sx={{margin: 1}}>Keep Subscription</Button>
                </Link>
            </Paper>
        </>
    )
}

export default SubscriptionCancelView;