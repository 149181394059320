import React, { useState } from 'react';

import httpClient from '../httpClient';
import {baseURL} from '../config/constants/url';

const FaqItem = (props) => {
    const faq = props.faq;
    return (
        <>
            <p><b>Q: {faq.question}</b></p>
            <p>A: {faq.answer}</p>
            <br/>
        </>
    );
}

const SupportView = (props) => {

    const [faqs, setFaqs] = useState(null);

    if (faqs === null) {
        httpClient.get(new URL('/faq', baseURL)).then((response) => {
            if (response?.data?.faqs) {
                setFaqs(response.data.faqs);
            }
        }).catch((reason) => {
            console.log("Error getting faqs:");
            console.log(reason);
        })
    }

    return (
        <>
            <div>
                <h3>Frequently Asked Questions</h3>
                {faqs.map((faq) => {
                    return <FaqItem faq={faq} />
                })}
            </div>
            <div>
                <h3>Contact Information</h3>
                <p>Lorem ipsum</p>
            </div>
            <div>
                <h3>About</h3>

            </div>
        </>
    )
}

export default SupportView;