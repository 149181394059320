import axios from 'axios'
import jwtDecode from 'jwt-decode'

import {baseURL} from './config/constants/url';

// instantiate axios
const httpClient = axios.create({
  baseURL: baseURL
});

httpClient.interceptors.request.use(
  (config) => {
    const token = httpClient.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      // const decodedToken = jwtDecode(token);
      // const currentTime = Date.now() / 1000;
      // if (decodedToken.exp < currentTime) {
      //   httpClient.logOut();
    
      //   // redirect to login
      //   window.location.href = '/login';
      // }
    } else {
      // delete config.headers['Authorization'];
      // httpClient.logOut();
      // window.location.href = '/login';
    }
    return Promise.resolve(config);
  },
  (error) => {
    if (error) {
      
      // ensure we call the reject function
      return Promise.reject(error);
    }
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.data?.token?.KEY === 'ERR_EXPIRED_TOKEN')) {
      // redirect to login
      httpClient.clearToken();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

httpClient.getToken = function () {
  return localStorage.getItem('token');
}

httpClient.setToken = function (token) {
  localStorage.setItem('token', token)
  return token;
}

httpClient.clearToken = function() {
  return localStorage.clear();
}

httpClient.getCurrentUser = function () {
  const token = this.getToken()
  if (token) return jwtDecode(token);
  return null;
}

httpClient.currentUserIsOrgAdmin = function () {
  const user = this.getCurrentUser();
  return user?.organization?.accountAdmin === user._id;
}

httpClient.logIn = function (credentials) {
  return this({ method: 'post', url: new URL('/user/login', baseURL), data: credentials })
    .then((serverResponse) => {
      if (serverResponse.status === 400) {
        console.log("Invalid username or password. Please try again.");
        return false;
      }
      else if (!serverResponse.data.isLogin) {
        alert(serverResponse.data.message);
        return false;
      }
      const token = serverResponse.data.user.token;
      if (token) {
        // sets token as an included header for all subsequent api requests
        this.defaults.headers.common.token = this.setToken(token);
        return jwtDecode(token);
      } else {
        return false;
      }
    }).catch((error) => {
      alert("Error logging in with remote server.");
      console.log("Error logging in with remote server:");
      console.log(error);
      return false;
    });
}

// logIn and signUp functions could be combined into one since the only difference is the url we're sending a request to..
httpClient.signUp = function (userInfo) {
  return this({ method: 'post', url: new URL('/user/registration', baseURL), data: userInfo })
    .then((serverResponse) => {
      if (!serverResponse.data.isRegister) {
        alert(serverResponse.data.message);
        return Promise.reject(serverResponse.data.message);
      }
      const token = serverResponse.data.user.token;
      if (token) {
        // sets token as an included header for all subsequent api requests
        this.defaults.headers.common.token = this.setToken(token);
        return Promise.resolve(jwtDecode(token));
      } else {
        return Promise.reject("No token in server response");
      }
    })
}

httpClient.logOut = function () {
  localStorage.removeItem('token')
  delete this.defaults.headers.common.token;
  return true;
}

// During initial app load attempt to set a localStorage stored token
// as a default header for all api requests.
httpClient.defaults.headers.common.token = httpClient.getToken();
export default httpClient;
