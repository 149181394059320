import React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useNavigate } from 'react-router-dom';

import httpClient from '../httpClient';

import {calculateTireRevsPerMile} from '../utils';

const CalcTireSizeForm = (props) => {

    const [revsPerMile, setRevsPerMile] = React.useState();

    const navigate = useNavigate();

    const validationSchema = yup.object({
        tireSizeDesignation: yup.string().required(),
        revsPerMile: yup.number().positive()
    });

    const handleSubmit = (values) => {
        // validate
        if (revsPerMile === undefined || isNaN(revsPerMile) || revsPerMile <= 0) {
            alert("Invalid revs per mile value");
            return;
        }
        const updateObject = {
            revsPerMile: revsPerMile
        };
        httpClient.put(
            "/asset/" + props.assetId,
            updateObject
        ).then((response) => {
            // show confirmation
            alert("Tire information saved successfully.");

            // if there is a nextView, navigate to it
            if (props.nextView) {
                navigate(props.nextView);
            }
        }).catch((reason) => {
            console.log("Error submitting revsPerMile from tire lookup form:");
            console.log(reason);
            alert(
                "There was a problem saving the information to the database. " + 
                "Please refresh the page and try again."
            );
        });
    }

    const formik = useFormik({
        initialValues: {
            tireSizeDesignation: undefined,
            revsPerMile: revsPerMile
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    React.useEffect(() => {
        if (formik.values.tireSizeDesignation !== '' && formik.touched.tireSizeDesignation) {
            const newRevsPerMile = calculateTireRevsPerMile(formik.values.tireSizeDesignation);
            setRevsPerMile(newRevsPerMile);
        }
    }, [formik.values.tireSizeDesignation, formik.touched.tireSizeDesignation, formik.setFieldValue])

    return (
        <>
            <Box component="form" onSubmit={formik.handleSubmit} autoComplete='off' marginTop={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField 
                            id="tireSizeDesignationEntry" 
                            name="tireSizeDesignation" 
                            label="Tire Size Designation" 
                            fullWidth
                            value={formik.values.tireSizeDesignation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.tireSizeDesignation && Boolean(formik.errors.tireSizeDesignation)}
                            helperText={formik.touched.tireSizeDesignation && formik.errors.tireSizeDesignation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>Revolutions per Mile: {revsPerMile?.toFixed(0)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" type="submit" variant='contained' fullWidth>Submit</Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CalcTireSizeForm;
