import React, { Component } from 'react';

// import Geocode from 'react-geocode';
import { InfoWindowF, MarkerF, CircleF } from '@react-google-maps/api';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AdjustIcon from '@mui/icons-material/Adjust';
import { Grid } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import withGoogleMap from './withGoogleMap';
import { baseURL } from '../../config/constants/url';
// import { mapsAPIKey } from '../../config/constants/maps';
import { reverseGeocodeCall } from '../../utils';

//const MIN_ACCURACY_FOR_CIRCLE = 15      // in meters 
const MIN_ACCURACY_FOR_CIRCLE = 0      // always show 

class HistoryMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textLocation: '',
      isExpanded: false

    };
  }

  componentDidUpdate = () => {
    if (this.state.textLocation === '') {
      reverseGeocodeCall(this.props.historyItem, (address) => {
        this.setState({
          textLocation: address
        });
      })
    }
  }

  setInfoWindowInstance = (infoWindow) => {
    this.infoWindow = infoWindow;
  }

  handleToggleOpen = () => {
    if (this.infoWindow) {
      this.infoWindow.open(this.props.map);
    }
    this.setState({ isExpanded: true });
  }

  handleToggleClose = () => {
    this.infoWindow.close();
    this.setState({ isExpanded: false });
  }

  handleMarkerLoad = (marker) => {
    this.markerInstance = marker;
    // console.log("Key: " + this.props.markerID);
    window.google.maps.event.addListener(this.markerInstance.getMap(), 'triggerOpen-' + this.props.markerID, () => {
      // alert("TRIPMARKER " + this.props.markerID + " clicked!");
      window.google.maps.event.trigger(this.markerInstance.getMap(), 'triggerClosed');
      window.google.maps.event.addListenerOnce(this.markerInstance.getMap(), 'triggerClosed', () => {
        this.handleToggleClose();
      });
      this.handleToggleOpen();
    });
  }

  render() {
    // console.log("RENDER MARKER " + this.props.markerID);
    const loc = this.props.historyItem;

    // console.log(`Device ${device.name} lastLocation:`);
    // console.log(`${device.lastLocation.location.coordinates[1]}, ${device.lastLocation.location.coordinates[0]}`);
    const pointLatLng = {
      lat: loc.location.coordinates[1],
      lng: loc.location.coordinates[0]
    }
    const locationAccuracy = loc.accuracyMeters;
    // console.log(JSON.stringify(pointLatLng))

    const markerLabelStyle = {
      textAlign: 'center'
    }
    let icon, anchorPoint;
    if (this.props.current) {
      icon = '/truck_icon.svg';
      anchorPoint = { x: 24, y: 24 };
    } else {
      icon = '/Ellipse 109.svg'
      anchorPoint = { x: 12, y: 12 };
    }
    // console.log(this.state);
    return (
      <>
        <MarkerF
          key={this.props.markerID}
          markerID={this.props.markerID}
          id={this.props.markerID}
          title={loc.ts}
          position={pointLatLng}
          // label={device.name}
          // labelStyle={markerLabelStyle}
          // labelAnchor={{
          //   x: 10,
          //   y: 80
          // }}
          icon={{
            url: icon,
            anchor: anchorPoint
          }}
          onClick={this.handleToggleOpen}
          onLoad={this.handleMarkerLoad}
        // location={device.lastLocation.location}
        >
          {this.state.isExpanded === true && (
            <InfoWindowF
              key={`infowindow-${this.props.markerID}`}
              anchor={this.markerInstance}
              onLoad={this.setInfoWindowInstance}
              // position={deviceLatLng}
              onCloseClick={this.handleToggleClose}
              sx={{
                'button.gm-ui-hover-effect': {
                  visibility: 'hidden'
                }
              }}
            >
              <Grid container>
                <Grid item xs={1}>
                  <GpsFixedIcon />
                </Grid>
                <Grid item xs={11}>
                  {/* <p>{this.props.ts}</p>
                <hr />
                <p>{this.state.textLocation}</p> */}
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b>Timestamp</b>:
                          </TableCell>
                          <TableCell align="right">
                            {new Date(this.props.historyItem.ts).toLocaleString()}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>Address</b>:
                          </TableCell>
                          <TableCell align="right">
                            {this.state.textLocation}
                          </TableCell>
                        </TableRow>
                        {(locationAccuracy !== undefined) ? (
                          <TableRow>
                            <TableCell>
                              <b>Location Accuracy</b>:
                            </TableCell>
                            <TableCell align="right">
                              within {locationAccuracy} m
                            </TableCell>
                          </TableRow>
                        ) : <></>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>


                </Grid>
              </Grid>
            </InfoWindowF>
          )}
        </MarkerF>
        {(locationAccuracy !== undefined && locationAccuracy > MIN_ACCURACY_FOR_CIRCLE) ?
          <CircleF
            key={this.props.markerID + '_acc'}
            center={pointLatLng}
            radius={locationAccuracy}
            options={{
              fillColor: '#1462ac',
              fillOpacity: 0.15,
              strokeColor: "#1462ac",
              strokeOpacity: 0.5
            }}
          /> :
          <></>
        }
      </>
    )
  }
  // console.log("Device missing location: " + device.deviceID);
  // return (<div key={device.deviceID}></div>);
}


export default withGoogleMap(HistoryMarker);