import httpClient from "../../httpClient";

export async function getStripePK() {
    try {
        const response = await httpClient.get("/stripe/publishable-key");
        // return response.data.publishableKey;
        sessionStorage.setItem('stripePublishableKey', response.data.publishableKey);
        return response.data.publishableKey;
    } catch(e) {
        console.log("Error getting stripe publishable key:");
        console.log(e);
        throw e;
    }
}

getStripePK();

export const stripePublishableKey = sessionStorage.getItem('stripePublishableKey');

