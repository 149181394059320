import React from 'react'
import httpClient from '../httpClient'

import Card from 'react-bootstrap/Card';
// import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { Formik, Form, ErrorMessage } from 'formik';

import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import withRouter from '../withRouter';

const defaultFields = {
  username: '',
  email: '',
  password: '',
  passwordConfirm: '',
  fullName: '',
}

const SignUpSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, 'Username is too short.')
    .max(50, 'Username is too long (max 50 characters).')
    .required('Please enter a username.'),
  password: Yup.string()
    .min(8, 'Password too short (minimum 8 characters).')
    .max(50, 'Password too long (maximum 50 characters).')
    .required('Please enter a password.'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('You must confirm your password.'),
  fullName: Yup.string()
    .max(128, 'Full Name field is too long (max 128 characters)')
    .required('Please enter your name.'),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Please enter a valid email.')
});

// sign up form behaves almost identically to log in form. We could create a flexible Form component to use for both actions, but for now we'll separate the two:
class SignUp extends React.Component {
  state = {
    fields: defaultFields
  }

  // onInputChange(evt) {
  //   this.setState({
  //     fields: {
  //       ...this.state.fields,
  //       [evt.target.name]: evt.target.value
  //     }
  //   })
  // }

  // onFormSubmit(evt) {
  //   evt.preventDefault()
  //   httpClient.signUp(this.state.fields).then(user => {
  // this.setState({ fields: defaultFields })
  // if (user) {
  //   this.props.onSignUpSuccess(user)
  //   this.props.history.push('/')
  // }
  //   })
  // }
  handleSubmit = (values, {
    props = this.props,
    setSubmitting
  }) => {
    httpClient.signUp(values).then((user) => {
      // this.setState({ fields: defaultFields })
      if (user) {
        this.props.onSignUpSuccess(user);
        // this.props.history.push('/')
        this.props.navigate('/');
      }
      setSubmitting(false);
      return;
    }).catch((reason) => {
      alert(reason);
      setSubmitting(false);
      return;
    });
  }

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={defaultFields}
        validationSchema={SignUpSchema}
        onSubmit={this.handleSubmit}
      >
        {
          ({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            isSubmitting
          }) => (
            // <p>TESTING</p>
            // <Card className='container'>

            <Card className='container'>

              <Form>
                <h2>Sign Up</h2>

                <FormGroup controlId='username'>
                  <FormLabel>Username</FormLabel>
                  <FormControl
                    type='text'
                    value={values.username}
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormGroup>
                <ErrorMessage component={Alert} name="email" variant="warning" />

                <FormGroup controlId='email'>
                  <FormLabel>Email Address</FormLabel>
                  <FormControl
                    type='text'
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormGroup>
                <ErrorMessage component={Alert} name="email" variant="warning" />

                <FormGroup controlId='password'>
                  <FormLabel>Password</FormLabel>
                  <FormControl
                    type='password'
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // placeholder="Password"
                  />
                </FormGroup>
                <ErrorMessage component={Alert} name="password" variant="warning"/>

                <FormGroup controlId='passwordConfirm'>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl
                    type='password'
                    value={values.passwordConfirm}
                    name="passwordConfirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // placeholder="Confirm Password"
                  />
                </FormGroup>
                <ErrorMessage component={Alert} name="passwordConfirm" variant="warning"/>

                <FormGroup controlId='fullName'>
                  <FormLabel>Full Name</FormLabel>
                  <FormControl
                    type='text'
                    value={values.fullName}
                    name="fullName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormGroup>
                <ErrorMessage component={Alert} name="fullName" variant="warning"/>

                <Button variant="primary" type="submit" disabled={isSubmitting}>Sign Up</Button>

                <FormText>Already have an account? <Link to={'/login'}>Log in here</Link>.</FormText>
              </Form>
            </Card>
            // </Card>

          )

        }
      </Formik>
      // <div className='SignUp'>
      //   <div className='row'>
      //     <div className='column column-33 column-offset-33'>
      //       <h1>Sign Up</h1>
      //       <form onChange={this.onInputChange.bind(this)} onSubmit={this.onFormSubmit.bind(this)}>
      //         <input type="text" placeholder="Name" name="name" value={name} />
      //         <input type="text" placeholder="Email" name="email" value={email} />
      //         <input type="password" placeholder="Password" name="password" value={password} />
      //         <button>Sign Up</button>
      //       </form>
      //     </div>
      //   </div>
      // </div>

    );
  }
}

export default withRouter(SignUp);
