import React from 'react';

import httpClient from '../httpClient';

import AdminUserList from '../components/AdminUserList';

/**
 * Container for admin user management tool
 *
 * @class UserManage
 * @extends {React.Component}
 */
export default class UserManage extends React.Component {

  state = {
    users: [],
  }

  componentDidMount() {
    httpClient.get('/user/list').then(res => {
      let users = res.data.user;
      if (!users) {
        users = [];
      }
      this.setState({users: users});
    });
  }

  render() {
    return <AdminUserList users={this.state.users} />
  }
}