import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
} from '@mui/material';
import * as yup from 'yup';

import withRouter from '../withRouter';
import httpClient from '../httpClient';

const validationSchema = yup.object().shape({
    name: yup.string('You must give this asset a name').required(),
    description: yup.string('Enter a description for this asset')
});

const CreateAssetForm = (props) => {

    const defaultValues = {
        name: "",
        description: "",
        assetInfo: {
            assetNo: "",
            assetType: "",
            category: "",
            tags: "",
            vin: "",

        }
    };

    let initialValues = props.editingAsset === undefined ? defaultValues : props.editingAsset;

    const [searchParams, setSearchParams] = useSearchParams();

    const handleSubmit = (values) => {
        httpClient.post(
            '/asset/',
            {
                asset: values
            }
        ).then((response) => {
            // alert(response);
            if (searchParams.returnTo) {
                props.navigate(searchParams.returnTo);
            } else {
                props.navigate('/assets');
            }
        }).catch((reason) => {
            alert("Error submitting asset: " + reason);
        })
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    return (
        <>
            {props.editingAsset ? <h2>Edit Asset</h2> : <h2>Create Asset</h2>}
            <Box component={Container}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <TextField
                            fullWidth
                            margin='normal'
                            id="name"
                            name="name"
                            label="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            required
                        />
                        <TextField
                            fullWidth
                            margin='normal'
                            multiline
                            rows={3}
                            id="description"
                            name="description"
                            label="Description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                        />

                        <TextField
                            fullWidth
                            margin='normal'
                            id="assetInfo.assetNo"
                            name="assetInfo.assetNo"
                            label="Asset Number"
                            value={formik.values['assetInfo.assetNo']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['assetInfo.assetNo'] && Boolean(formik.errors['assetInfo.assetNo'])}
                            helperText={formik.touched['assetInfo.assetNo'] && formik.errors['assetInfo.assetNo']}
                        />

                        <TextField
                            fullWidth
                            margin='normal'
                            id="assetInfo.assetType"
                            name="assetInfo.assetType"
                            label="Asset Type"
                            value={formik.values['assetInfo.assetType']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['assetInfo.assetType'] && Boolean(formik.errors['assetInfo.assetType'])}
                            helperText={formik.touched['assetInfo.assetType'] && formik.errors['assetInfo.assetType']}
                        />

                        <TextField
                            fullWidth
                            margin='normal'
                            id="assetInfo.category"
                            name="assetInfo.category"
                            label="Category"
                            value={formik.values['assetInfo.category']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['assetInfo.category'] && Boolean(formik.errors['assetInfo.category'])}
                            helperText={formik.touched['assetInfo.category'] && formik.errors['assetInfo.category']}
                        />

                        <TextField
                            fullWidth
                            margin='normal'
                            id="assetInfo.tags"
                            name="assetInfo.tags"
                            label="Tags"
                            value={formik.values['assetInfo.tags']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['assetInfo.tags'] && Boolean(formik.errors['assetInfo.tags'])}
                            helperText={formik.touched['assetInfo.tags'] && formik.errors['assetInfo.tags']}
                        />

                        <TextField
                            fullWidth
                            margin='normal'
                            id="assetInfo.vin"
                            name="assetInfo.vin"
                            label="VIN"
                            value={formik.values['assetInfo.vin']}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched['assetInfo.vin'] && Boolean(formik.errors['assetInfo.vin'])}
                            helperText={formik.touched['assetInfo.vin'] && formik.errors['assetInfo.vin']}
                        />

                        <br />

                        <Button sx={{ marginTop: 3 }} color='primary' variant='contained' type='submit'>Create Asset</Button>
                    </Grid>
                </form>
            </Box>
        </>
    )
}

export default withRouter(CreateAssetForm);
