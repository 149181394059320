import React, {useEffect, useState} from 'react';
// import axios from 'axios';
import httpClient from '../httpClient';
import {useParams} from 'react-router-dom';
import AdminUserDetail from '../components/AdminUserDetail';

/**
 * Container for admin user management tool
 *
 * @class UserManage
 * @extends {React.Component}
 */
export default function UserDetail(props) {
  const [user, setUser] = useState();
  const params = useParams();
  
  useEffect(() => {
    if (user !== undefined) {
      return;
    }
    httpClient.get('/user/' + params.userID).then(res => {
      setUser(res.data.user);
      // if (!user) {
      //   user = {};
      // }
      // this.setState({
      //   user: user
      // });

    }).catch((reason) => {
      console.log("Error getting user information");
      console.log(reason);
    });
  });

  return (
    <>
      {(user) ? (
        <AdminUserDetail user={user} />
      ) : (<></>)}
    </>
  )

}