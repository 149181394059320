import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Button } from '@mui/material';
import { confirmAlert } from "react-confirm-alert";

import { baseURL } from '../config/constants/url';
import httpClient from '../httpClient';
import withRouter from '../withRouter';

import DeviceAssetForm from '../components/DeviceAssetForm';
import DeviceDetailPanel from '../panels/DeviceDetailPanel';
import EditAlarm from '../components/EditAlarm';

class DeviceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: undefined,
      revData: []
    };
  }

  zeroOdometerRequest = () => {
    confirmAlert({
      title: <h4>Confirm Reset</h4>,
      message: (
        <p>
          Are you sure you wish to reset the odometer reading to zero? Lifetime
          distance traveled will be retained, but the current value will be
          lost. <strong>This cannot be undone!</strong>
        </p>
      ),
      buttons: [
        {
          label: "Cancel",
          onClick: () => {},
        },
        {
          label: "Reset to Zero",
          onClick: () => {
            httpClient
              .post(
                new URL(
                  "/device/zero-mileage/" + this.state.device.deviceID,
                  baseURL
                ),
                {}
              )
              .then((response) => {
                alert("The odometer was reset successfully!");
                this.setState({device: {...response.data.device}});
              })
              .catch((reason) => {
                alert(
                  "There was an error resetting the odometer. Please contact support if the error persists."
                );
              });
          },
        },
      ],
    });
  };

  updateDevice = () => {
    httpClient.get(
      '/device/' + this.props.params.deviceID
    ).then(res => {
      this.setState({
        device: {...res.data.device},
        // revData: this.state.revData,
      });
    });
  }

  updateRevs = () => {
    httpClient.get(
      '/device/revdata/' + this.props.params.deviceID
    ).then(res => {
      this.setState({
        // device: this.state.device,
        revData: res.data.revData
      });
    });
  }

  updateAll = () => {
    this.updateDevice();
    this.updateRevs();
  }

  componentDidMount() {
    this.updateAll();
    // this.interval = setInterval(() => {
    //   this.updateDevice();
    // }, 15000);
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
  }

  render() {
    if (!this.state.device) {
      return (<p>Loading...</p>);
    }
    return (
      <div>
        <Button sx={{float: 'right'}} variant="contained" type="button" onClick={this.updateAll}>Refresh</Button>
        <h2>Detail View - {this.state.device.name} <i>(ID: {this.state.device.deviceID})</i></h2>
        <Tabs defaultActiveKey='data' id='deviceDataTab'>
          <Tab eventKey='data' title="Telemetry">
            <DeviceDetailPanel device={this.state.device} revData={this.state.revData} onResetOdometer={this.zeroOdometerRequest} user={this.props.user}/>
          </Tab>
          <Tab eventKey='asset' title="Asset Info">
            <DeviceAssetForm device={this.state.device} user={this.props.user}/>
          </Tab>
          <Tab eventKey='alarms' title="Alert Config">
            <EditAlarm device={this.state.device} />
          </Tab>
        </Tabs>
      </div>
    )
  }
}

export default withRouter(DeviceDetail);
