import React from 'react';

import { Formik, Form, FormikProps, ErrorMessage } from 'formik';
// import FormGroup from 'react-bootstrap/FormGroup';
// import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormCheck from 'react-bootstrap/FormCheck';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import httpClient from '../httpClient';
import { baseURL } from '../config/constants/url';

// import {diameterToRevsPerMile, revsPerMileToDiameter} from '../utils';

// Map internal types to AlertType magic string enum used in API
const alertTypeMapping = {
  speed: 'Speed',
  maintenance: 'Scheduled Maintenance'
}

function ValidateForm(values) {
  let errors = {};

  return errors;
}

function AlarmNotificationEnable(props) {
  const formikHooks = props.formikHooks;
  return (
    <FormCheck
      inline="inline"
      label="Enable alert messages"
      name={props.name}
      type="checkbox"
      id={props.name}
      value="on"
      onChange={formikHooks.handleChange}
      onBlur={formikHooks.handleBlur}
      checked={formikHooks.values[props.name]}
    />
  );
}

/**
 * Component to handle alarm configuration at the device level.
 *
 * @export
 * @class EditAlarm
 * @extends {React.Component}
 */
export default class EditAlarm extends React.Component {
  handleSubmit = (values, { props = this.props, setSubmitting }) => {
    const submitValues = {
      // deviceID: this.props.device.deviceID,
      // device: this.props.device._id,
      alertMedium: {
        email: values.alertMedium.email === true,
        sms: values.alertMedium.sms === true,
        pushNtf: values.alertMedium.pushNtf === true,
      },
      alertTypes: [],
      speed: {
        enabled: values.speedAlarm === 'on',
        notify: values.speedAlarmNotify === true,
        threshold: values.speedAlarmMPH,
        unit: 'MPH',
      },
      maintenance: {
        timeBase: {
          enabled: values.timeMaintAlert === 'on',
          notify: values.timeMaintAlertNotify === true,
          intervalDays: values.maintAlertDays,
        },
        mileageBase: {
          enabled: values.mileMaintAlert === 'on',
          notify: values.mileMaintAlertNotify === true,
          intervalMiles: values.maintAlertMiles,
        },
      },
    };

    // Add alertTypes for active alarm rules
    if (values.mileMaintAlert === 'on' || values.timeMaintAlert === 'on') {
      submitValues.alertTypes.push(alertTypeMapping.maintenance);
    }
    if (values.speedAlarm === 'on') {
      submitValues.alertTypes.push(alertTypeMapping.speed);
    }

    httpClient
      .put(
        new URL('/alertSetting/' + this.props.device._id, baseURL),
        submitValues
      )
      .then((response) => {
        alert(response.statusText);
        setSubmitting(false);
        return;
      })
      .catch((reason) => {
        alert(reason);
        setSubmitting(false);
        return;
      });
    // Alert probably not needed
    // alert(JSON.stringify(values, null, 2));
  };

  constructor(props) {
    super(props);
    this.state = {
      alertSetting: undefined,
    };
  }

  componentDidMount() {
    if (!this.props.device) {
      return;
    }
    httpClient
      .get('/alertSetting/' + this.props.device._id)
      .then((response) => {
        this.setState({ alertSetting: response.data.alertSetting });
      })
      .catch((reason) => {
        console.log('Error getting alert setting:');
        console.log(reason);
      });
  }

  render() {
    const device = this.props.device;
    const initialValues = {
      deviceID: device.deviceID,
      alertMedium: {
        email: this.state.alertSetting?.alertMedium.email === true,
        sms: this.state.alertSetting?.alertMedium.sms === true,
        pushNtf: this.state.alertSetting?.alertMedium.pushNtf === true,
      },

      // placeholder
      speedAlarm: this.state.alertSetting?.speed.enabled ? 'on' : 'off',
      speedAlarmMPH: this.state.alertSetting?.speed.threshold,
      speedAlarmNotify: this.state.alertSetting?.speed.notify === true,

      // speedAlarm: device.alarmRules.speedAlarm.enabled ? 'on' : 'off',
      // speedAlarmMPH: device.alarmRules.speedAlarm.mph,
      // speedAlarmNotify: device.alarmRules.speedAlarmNotify ? 'on' : 'off',

      // missedPost: device.alarmRules.missedPost.enabled ? 'on' : 'off',
      // missedPostDays: device.alarmRules.missedPost.days,
      // missedPostNotify: device.alarmRules.missedPostNotify ? 'on' : 'off',

      mileMaintAlert: this.state.alertSetting?.maintenance.mileageBase.enabled
        ? 'on'
        : 'off',
      maintAlertMiles:
        this.state.alertSetting?.maintenance.mileageBase.intervalMiles,
      mileMaintAlertNotify: this.state.alertSetting?.maintenance.mileageBase.notify === true,

      timeMaintAlert: this.state.alertSetting?.maintenance.timeBase.enabled
        ? 'on'
        : 'off',
      maintAlertDays:
        this.state.alertSetting?.maintenance.timeBase.intervalDays,
      timeMaintAlertNotify: this.state.alertSetting?.maintenance.timeBase.notify === true,

      // tamperAlert: device.alarmRules.tamperAlert.enabled ? 'on' : 'off',
      // tamperAlertNotify: device.alarmRules.tamperAlertNotify ? 'on' : 'off',
    };
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={ValidateForm}
        onSubmit={this.handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <FormGroup style={{ display: 'none' }}>
              <FormLabel>Device ID</FormLabel>
              <FormControl
                type="text"
                name="deviceID"
                placeholder="Device ID"
                disabled
                onBlur={handleBlur}
                value={values.deviceID}
              />
              <ErrorMessage name="deviceID" />
            </FormGroup>

            <hr />
            <FormGroup>
              <Row>
                <Col>
                  <FormLabel>Speed Alarm</FormLabel>
                  <div key="inline-radio" className="mb-3">
                    <FormCheck
                      inline
                      label="off"
                      name="speedAlarm"
                      type="radio"
                      id="speedAlarm-off"
                      value="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.speedAlarm === 'off'}
                    />
                    <FormCheck
                      inline
                      label="on"
                      name="speedAlarm"
                      type="radio"
                      id="speedAlarm-on"
                      value="on"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.speedAlarm === 'on'}
                    />
                  </div>
                </Col>
                <Col>
                  {values.speedAlarm === 'on' ? (
                    <div>
                      <FormLabel for="speedAlarmDays">
                        Notify when speed exceeds:{' '}
                      </FormLabel>
                      <div className="form-inline">
                        <FormControl
                          type="number"
                          inline
                          name="speedAlarmMPH"
                          id="speedAlarmMPH"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.speedAlarmMPH}
                        />
                        <FormLabel inline>&nbsp;MPH</FormLabel>
                      </div>
                      <br />
                      <AlarmNotificationEnable
                        name="speedAlarmNotify"
                        formikHooks={{
                          values: values,
                          handleBlur: handleBlur,
                          handleChange: handleChange,
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <ErrorMessage name="speedAlarm" />
            </FormGroup>
            {/* <FormGroup>
              <Row>
                <Col>
                  <FormLabel>Missed Post Alarm</FormLabel>
                  <div key="inline-radio" className="mb-3">
                    <FormCheck
                      inline
                      label="off"
                      name="missedPost"
                      type="radio"
                      id="missedPost-off"
                      value="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.missedPost === 'off'}
                    />
                    <FormCheck
                      inline
                      label="on"
                      name="missedPost"
                      type="radio"
                      id="missedPost-on"
                      value="on"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.missedPost === 'on'}
                    />


                  </div>
                </Col>
                <Col>

                  {
                    (values.missedPost === 'on') ?
                      (
                        <div>
                          <FormLabel for="missedPostDays">Notify when no data has been received for: </FormLabel>
                          <div className="form-inline">
                            <FormControl
                              type="number"
                              inline
                              name="missedPostDays"
                              id="missedPostDays"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.missedPostDays}
                            />
                            <FormLabel inline>&nbsp;days</FormLabel>
                          </div>
                          <br />
                          <AlarmNotificationEnable
                            name="missedPostNotify"
                            formikHooks={{
                              values: values,
                              handleBlur: handleBlur,
                              handleChange: handleChange
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )
                  }
                </Col>
              </Row>
              <ErrorMessage name="missedPost" />
            </FormGroup> */}

            <hr />

            <FormGroup>
              <Row>
                <Col>
                  <FormLabel>Mileage-based Maintenance Alert</FormLabel>
                  <div key="inline-radio" className="mb-3">
                    <FormCheck
                      inline
                      label="off"
                      name="mileMaintAlert"
                      type="radio"
                      id="mileMaintAlert-off"
                      value="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.mileMaintAlert === 'off'}
                    />
                    <FormCheck
                      inline
                      label="on"
                      name="mileMaintAlert"
                      type="radio"
                      id="mileMaintAlert-on"
                      value="on"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.mileMaintAlert === 'on'}
                    />
                  </div>
                </Col>
                <Col>
                  {values.mileMaintAlert === 'on' ? (
                    <div>
                      <FormLabel for="maintAlertDays">
                        Notify for maintenance at:{' '}
                      </FormLabel>
                      <div className="form-inline">
                        <FormControl
                          type="number"
                          inline
                          name="maintAlertMiles"
                          id="maintAlertMiles"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.maintAlertMiles}
                        />
                        <FormLabel inline>&nbsp;miles</FormLabel>
                      </div>
                      <br />
                      <AlarmNotificationEnable
                        name="mileMaintAlertNotify"
                        formikHooks={{
                          values: values,
                          handleBlur: handleBlur,
                          handleChange: handleChange,
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </FormGroup>

            <hr />

            <FormGroup>
              <Row>
                <Col>
                  <FormLabel>Time-based Maintenance Alert</FormLabel>
                  <div key="inline-radio" className="mb-3">
                    <FormCheck
                      inline
                      label="off"
                      name="timeMaintAlert"
                      type="radio"
                      id="timeMaintAlert-off"
                      value="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.timeMaintAlert === 'off'}
                    />
                    <FormCheck
                      inline
                      label="on"
                      name="timeMaintAlert"
                      type="radio"
                      id="timeMaintAlert-on"
                      value="on"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.timeMaintAlert === 'on'}
                    />
                  </div>
                </Col>
                <Col>
                  {values.timeMaintAlert === 'on' ? (
                    <div>
                      <FormLabel for="maintAlertDays">
                        Notify for maintenance after:{' '}
                      </FormLabel>
                      <div className="form-inline">
                        <FormControl
                          type="number"
                          inline
                          name="maintAlertDays"
                          id="maintAlertDays"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.maintAlertDays}
                        />
                        <FormLabel inline>&nbsp;days</FormLabel>
                      </div>
                      <br />
                      <AlarmNotificationEnable
                        name="timeMaintAlertNotify"
                        formikHooks={{
                          values: values,
                          handleBlur: handleBlur,
                          handleChange: handleChange,
                        }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </FormGroup>

            <hr />
            <FormGroup>
              <FormLabel>
                Receive the following notification types for the above alarm
                rules:
              </FormLabel>
              <Row>
                <Col sm={2}>
                  <FormCheck
                    inline="inline"
                    name="alertMedium.email"
                    label="Email"
                    type="checkbox"
                    id="alertMedium.email"
                    value="on"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.alertMedium?.email}
                  />
                </Col>
                <Col sm={2}>
                  <FormCheck
                    inline="inline"
                    name="alertMedium.sms"
                    label="SMS (Text Messages)"
                    type="checkbox"
                    id="alertMedium.sms"
                    value="on"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.alertMedium?.sms}
                  />
                </Col>
                <Col sm={2}>
                  <FormCheck
                    inline="inline"
                    name="alertMedium.pushNtf"
                    label="Push Notifications"
                    type="checkbox"
                    id="alertMedium.pushNtf"
                    value="on"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.alertMedium?.pushNtf}
                  />
                </Col>
              </Row>
            </FormGroup>
            {/* <FormGroup>
              <FormLabel>Tamper Alert</FormLabel>
              <Row>
                <Col>
                  <div key="inline-radio" className="mb-3">
                    <FormCheck
                      inline
                      label="off"
                      name="tamperAlert"
                      type="radio"
                      id="tamperAlert-off"
                      value="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.tamperAlert === 'off'}
                    />
                    <FormCheck
                      inline
                      label="on"
                      name="tamperAlert"
                      type="radio"
                      id="tamperAlert-on"
                      value="on"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.tamperAlert === 'on'}
                    />
                  </div>
                </Col>
                <Col>
                  {
                    (values.tamperAlert === 'on') ?
                      (
                        <div>
                          <AlarmNotificationEnable
                            name="tamperAlertNotify"
                            formikHooks={{
                              values: values,
                              handleBlur: handleBlur,
                              handleChange: handleChange
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )
                  }
                </Col>
              </Row>

            </FormGroup> */}

            {/* <FormGroup>
              <FormLabel >Alarm Type</FormLabel>
              <FormControl
                as="select"
                name="alarmType"
                value={values.alarmType}
              >
                <option value="basicDistanceThreshold">Basic Distance Threshold</option>
              </FormControl>
              <ErrorMessage name="alarmType" />
            </FormGroup>
            <AlarmTypeOptions
              alarmType={values.alarmType}
              values={values}
            /> */}
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}
