import React from 'react';

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';

import {useTheme} from '@mui/material/styles';

import TireLookupForm from '../components/TireLookupForm';
import CalcTireSizeForm from '../components/CalcTireSizeForm';
import RevsPerMileEntry from '../components/RevsPerMileEntry';

const ConfigureTireSizeView = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box
            sx={{
                marginTop: 2,
                // display: 'flex',
                // flexDirection: 'column',
                // maxWidth: '600px',
                alignmentBaseline: 'center',
                alignItems: 'center'
            }}
        >

            <Typography variant="h4" gutterBottom>Tire Size Configuration</Typography>
            <Grid container>
                <Grid item xs={12} md={3}>
                    <Card variant="outlined" sx={{ mb: 1 }} >
                        <CardContent>
                            <Typography variant="h6"><strong>Option 1</strong>: Look up tire by brand</Typography>
                            <Typography variant="caption" gutterBottom><i>(most accurate)</i></Typography>
                            <TireLookupForm assetId={props.assetId} nextView={props.nextView} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={1}>
                    {smallScreen ?
                        <Divider sx={{width: '100%'}}>OR</Divider>
                        : <Divider orientation='vertical'>OR</Divider>
                    }
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card variant="outlined">
                        <CardContent>

                            <Typography variant="h6" gutterBottom><strong>Option 2</strong>: Enter known revolutions per mile</Typography>
                            <RevsPerMileEntry assetId={props.assetId} nextView={props.nextView} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={1}>
                    {smallScreen ?
                        <Divider sx={{width: '100%'}}>OR</Divider>
                        : <Divider orientation='vertical'>OR</Divider>
                    }
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card variant="outlined">
                        <CardContent>

                            <Typography variant="h6"><strong>Option 3</strong>: Calculate from tire size designation</Typography>
                            <Typography variant="caption" gutterBottom><i>(less accurate)</i></Typography>
                            <CalcTireSizeForm assetId={props.device} nextView={props.nextView} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ConfigureTireSizeView;
