import React from 'react';

import {
    Button,
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    Typography
} from '@mui/material';

import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en';

import httpClient from '../httpClient';

TimeAgo.addDefaultLocale(en);

function handleDisconnectClick(deviceID, assetID) {
    console.log("Disconnecting " + deviceID + " from " + assetID);
    httpClient.post('/asset/unassign-device',
        {
            deviceID: deviceID,
            assetID: assetID
        }
    ).then(response => {
        console.log("Successfully disconnected hubo");
        console.log(response);
    }).catch(error => {
        console.log("Error disconnecting hubo:");
        console.log(error);
    });
}

const HuboCard = (props) => {
    const device = props.device;

    // const lastPostedDate = (device.lastPosted) ? new Date(device.lastPosted) : "Never";

    return (
        <Card style={{
            width: '100%',
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
            textAlign: 'center',
            verticalAlign: 'middle',
        }}>
            <CardContent>
                <Grid container >
                    <Grid item sm={3}>
                        <Typography gutterBottom variant="p" style={{color: '#666'}}>
                            IMEI
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" style={{marginTop: '15px'}}>
                            {device.deviceID}
                        </Typography>
                    </Grid>
                    <Grid item sm={3}>
                        <Typography gutterBottom variant="p" style={{color: '#666'}}>
                            LAST POSTED
                        </Typography>
                        <Typography gutterBottom variant="h5" style={{marginTop: '15px'}}>
                            {device.lastPosted ? <ReactTimeAgo date={new Date(device.lastPosted)} /> : "Never"}
                        </Typography>
                    </Grid>
                    <Grid item sm={3}>
                        <Typography gutterBottom variant="p" style={{color: '#666'}}>
                            CURRENT ASSET
                        </Typography>
                        <Typography gutterBottom variant="h5" style={{marginTop: '15px'}}>
                            {device.boundAsset?.name}
                        </Typography>
                    </Grid>
                    {device.boundAsset ? (
                        <Grid item sm={3}>
                            <Button variant="contained" color="warning" onClick={() => handleDisconnectClick(device.deviceID, device.boundAsset._id)}>
                                Disconnect Hubo
                            </Button>
                        </Grid>
                    ) : (<></>)}
                </Grid>
            </CardContent>
        </Card>
    )
}

const HuboList = (props) => {
    const devices = props.devices;
    // console.log("devices = " + devices);
    if (!devices || devices.length === 0) return (<div>No devices found</div>);

    return (
        <>
            <Typography variant='h4'>All Hubos</Typography>
            <List style={{width: '100%'}}>
                {devices.map((device) =>
                    <ListItem key={device.deviceID + "-listitem"}>
                        <HuboCard device={device} />
                    </ListItem>
                )}
            </List>
        </>
    )
}

export default HuboList;