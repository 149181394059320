import React from 'react';
import { useFormik } from 'formik';
import { Box, Button, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import httpClient from '../httpClient';

const RevsPerMileEntry = (props) => {

    const validationSchema = yup.object({
        revsPerMile: yup.number().required().positive()
    });

    const navigate = useNavigate();

    const handleSubmit = (values) => {
        const revsPerMile = values.revsPerMile;
        // validate
        if (revsPerMile === undefined || isNaN(revsPerMile) || revsPerMile <= 0) {
            alert("Invalid revs per mile value");
            return;
        }
        const updateObject = {
            revsPerMile: revsPerMile
        };
        httpClient.put(
            "/asset/" + props.assetId,
            updateObject
        ).then((response) => {
            // show confirmation
            alert("Tire information saved successfully.");

            // if there is a nextView, navigate to it
            if (props.nextView) {
                navigate(props.nextView);
            }
        }).catch((reason) => {
            console.log("Error submitting revsPerMile from tire lookup form:");
            console.log(reason);
            alert(
                "There was a problem saving the information to the database. " + 
                "Please refresh the page and try again."
            );
        });
    }

    const formik = useFormik({
        initialValues: {
            revsPerMile: undefined
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    return (
        <>
            <Box component="form" autoComplete='off' onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField 
                            id="revsPerMileEntry" 
                            name="revsPerMile" 
                            label="Revolutions per Mile" 
                            fullWidth
                            value={formik.values.revsPerMile}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.revsPerMile && Boolean(formik.errors.revsPerMile)}
                            helperText={formik.touched.revsPerMile && formik.errors.revsPerMile}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" type="submit" variant='contained' fullWidth>Submit</Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default RevsPerMileEntry;