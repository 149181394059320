import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
// import Geocode from 'react-geocode';

import TripHistoryMap from '../components/maps/TripHistoryMap';
import { baseURL } from '../config/constants/url';
import httpClient from '../httpClient';
import { reverseGeocodeCall } from '../utils';
import withRouter from '../withRouter';

function HistoryAssetHeading(props) {
    // Check for empty history
    if (!props.asset || !props.asset.locationHistory || props.asset.locationHistory.length === 0) {
        return (
            <>
                <Typography variant='h6'>{props.asset.name}</Typography>
                <Typography variant='body1'>
                    Odometer: {props.asset.currentMileage.toFixed(2)} | No location history data recorded
                </Typography>
            </>
        )
    }

    // location history should come back in date-descending order
    const endDate = props.asset.locationHistory[0].ts;
    const startDate = props.asset.locationHistory[props.asset.locationHistory.length - 1].ts;
    return (
        <>
            <Typography variant='h6'>{props.asset.name}</Typography>
            <Typography variant='body1'>
                Odometer: {props.asset.currentMileage.toFixed(2)} | {new Date(startDate).toLocaleString()} to {new Date(endDate).toLocaleString()}
            </Typography>
        </>
    )
}

class TripHistoryItem extends Component {
    constructor(props) {
        super(props);
        const addressAlreadyLoaded = props.historyItem.streetLocation !== undefined;
        this.state = {
            textLocation: addressAlreadyLoaded ? props.historyItem.streetLocation : 'Loading...',
            locationLoaded: addressAlreadyLoaded
        }
    }

    componentDidMount() {
        if (!this.state.locationLoaded) {
            reverseGeocodeCall(this.props.historyItem, (address) => {
                this.setState({
                    textLocation: address,
                    locationLoaded: true
                });
            })
        }
    }

    render() {
        return (
            <ListItem onClick={this.props.onClick} alignItems='flex-start'>
                <ListItemIcon>
                    <FmdGoodOutlinedIcon style={{ color: '#1462AC' }} />
                </ListItemIcon>
                <ListItemText primary={
                    <>
                        <Typography variant="body1">{this.state.textLocation}</Typography>
                        <Typography variant="caption">{new Date(this.props.historyItem.ts).toLocaleString()}</Typography>
                    </>
                } />
            </ListItem>
        )
    }
}

function TripHistoryList(props) {
    return (
        <List>
            {props.asset.locationHistory.map((historyItem, index) =>
                <TripHistoryItem
                    historyItem={historyItem}
                    key={"historyListItem" + index}
                    onClick={(e) => {
                        // toggle open corresponding HistoryMarker's InfoWindow
                        window.google.maps.event.trigger(props.mapInstance, 'triggerOpen-history' + index);
                    }}
                    sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                />
            )}
        </List>
    )
}

class AssetTripHistoryView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            asset: null,
            assetLoaded: false,
            mapInstance: null
        }
    }

    handleMapLoad = (mapInstance) => {
        this.setState({ mapInstance: mapInstance });
    }

    componentDidMount() {
        if (!this.state.assetLoaded) {
            var asset;
            httpClient.get(
                new URL('/asset/' + this.props.params.assetId, baseURL),
            ).then(res => {
                asset = res.data.asset;
                return httpClient.get(
                    new URL('/asset/trip-history/' + this.props.params.assetId, baseURL),
                )
            }).then(res => {
                asset.locationHistory = res.data.tripHistory;
                this.setState({
                    asset: asset,
                    assetLoaded: true
                });
            }).catch(reason => {
                console.log("Error getting asset information");
                console.log(reason);
            });
        }
    }


    render() {
        const mapContainerStyle = {
            height: '42vh'
        }
        return (
            <>
                <Link to={'/assetdetail/' + this.props.params.assetId}>
                    <Button size='large' variant="contained">Return to Asset View</Button>
                </Link>
                <Typography variant='h6'>Trip History</Typography>
                {(!this.state.assetLoaded) ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <TripHistoryMap
                            device={this.state.asset}
                            containerStyle={mapContainerStyle}
                            onMapLoad={this.handleMapLoad}
                        />
                        <HistoryAssetHeading asset={this.state.asset} />
                        <TripHistoryList asset={this.state.asset} mapInstance={this.state.mapInstance} />
                    </>
                )}
            </>
        )
    }
}

export default withRouter(AssetTripHistoryView);
