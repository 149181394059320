import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import httpClient from '../httpClient';
import GoogleMapPane from '../components/maps/GoogleMapPane';
import AlertsFeed from '../components/AlertsFeed';

import { baseURL } from '../config/constants/url';

export default class MainDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      devices: []
    }
  }

  componentDidMount() {
    httpClient.get(
      new URL('/device/', baseURL)
    ).then(response => {
      // let devices = this.state.devices;
      // if (response.data.devices) {
      //   devices = response.data.devices;
      // }
      this.setState({
        isLoading: false,
        devices: response.data.data
      })
    });
  }

  render() {
    const spinnerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'inherit',
      textAlign: 'center'
    };


    return (this.state.isLoading) ?
    (
      <div className='loading-div'>
        <div style={spinnerStyle}>
          <Spinner animation='border' size='lg'/>
        </div>
      </div>
    ) :
    (
      <Container fluid style={{height: '85%'}}>
        <Row className='full-height'>
          <Col md={3}>
          {/* <Col md={9}> */}
            <AlertsFeed devices={this.state.devices}/>
          </Col>
          <Col className='full-height'>
            <GoogleMapPane devices={this.state.devices} height="100%" highlightAlerts/>
          </Col>
        </Row>
      </Container>
    )
  }
}