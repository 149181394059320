// import logo from './logo.svg';
import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link,
  Navigate,
  // withRouter
} from 'react-router-dom';
import clsx from 'clsx';
import CacheBuster from 'react-cache-buster';
import { LoadScript } from '@react-google-maps/api';
import {
  Box,
  CssBaseline,
  // Container,
  // Grid,
  Toolbar,
} from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import packageJson from '../package.json';

// import Nav from 'react-bootstrap/Nav';
// import Container from 'react-bootstrap/Container';

import httpClient from './httpClient';
import getMapsAPIToken from './config/constants/maps';
import './App.css';

import MainRoutes from './routes';
// import history from './history';
import VRAppBar from './components/nav/VRAppBar';
// import LoginPage from './containers/LoginPage';
// import LogoutFunction from './containers/LogoutFunction';
// import SignUpPage from './containers/SIgnUpPage';
// import Auth from './modules/Auth';
// const axios = require('axios').default;

// import { mapsAPIKey } from "./config/constants/maps";

// REMOVE ME WHEN TESTING COMPLETE
// import {baseURL} from "./config/constants/url";
// console.log("Using baseURL " + baseURL);

const version = packageJson.version;

// const drawerWidth = 360;
// const useStyles = makeStyles(theme => ({
//   appBarSpacer: theme.mixins.toolbar
//   // root: {
//   //   display: "flex"
//   // },
//   // drawer: {
//   //   [theme.breakpoints.up("sm")]: {
//   //     width: drawerWidth,
//   //     flexShrink: 0
//   //   }
//   // },
//   // appBar: {
//   //   marginLeft: drawerWidth,
//   //   [theme.breakpoints.up("sm")]: {
//   //     width: `calc(100% - ${drawerWidth}px)`
//   //   },
//   // },
//   // menuButton: {
//   //   marginRight: theme.spacing(2),
//   //   [theme.breakpoints.up("sm")]: {
//   //     display: "none"
//   //   }
//   // },
//   // toolbar: theme.mixins.toolbar,
//   // drawerPaper: {
//   //   width: drawerWidth
//   // },
//   // content: {
//   //   flexGrow: 1,
//   //   padding: theme.spacing(3)
//   // }
// }));


function Loading(props) {
  return (
    <p>Loading, please wait...</p>
  )
}


class App extends Component {

  muiTheme = createTheme(theme => ({
    appBarSpacer: theme.mixins.toolbar
  }))

  constructor(props) {
    super(props);
    this.state = {
      currentUser: httpClient.getCurrentUser(),
      googleMapsApiKey: undefined,
      stripeClientSecret: undefined
    };
    //   this.state = {
    //     authenticated: false
    //   }
  }

  onLoginSuccess(user) {
    this.setState({
      currentUser: httpClient.getCurrentUser()
    });
    if (this.props?.redirectTo) {
      return <Navigate to={this.props.redirectTo} />
    }
  }

  logOut() {
    httpClient.logOut();
    this.setState({
      currentUser: null
    });
  }

  isAuthenticated() {
    return (this.state.currentUser !== null && this.state.currentUser !== undefined)
  }

  componentDidMount() {
    // Check auth on refresh
    // this.toggleAuthenticateStatus();
    getMapsAPIToken().then((token) => {
      this.setState({
        googleMapsApiKey: token
      });
    });

    // httpClient.get('/stripe/publishable-key').then((response) => {
    //   this.setState({
    //     stripePublishableKey: response.data.publishableKey
    //   });
    // });
  }

  // toggleAuthenticateStatus() {
  //   // this.setState({ authenticated: Auth.isUserAuthenticated() });
  // }


  // render() {
  //   return (
  //     <Router>
  //       <div className="App">
  //         <TopBanner isAuthenticated={Auth.isUserAuthenticated()}/>


  //         <PropsRoute exact path="/" component={HomePage} toggleAuthenticateStatus={() => this.toggleAuthenticateStatus()} />
  //         <PrivateRoute path="/dashboard" component={DashboardContainer}/>
  //         <LoggedOutRoute path="/login" component={LoginPage} toggleAuthenticateStatus={() => this.toggleAuthenticateStatus()} />
  //         <LoggedOutRoute path="/signup" component={SignUpPage} />
  //         <Route path="/logout" component={LogoutFunction} />
  //       </div>
  //     </Router>
  //   )
  // }

  render() {
    let currentUser = this.state.currentUser;
    const isProduction = process.env.NODE_ENV === 'production';

    // const stripe = loadStripe(stripePublishableKey, {
    //   betas: ['custom_checkout_beta_2'],
    // });

    if (!this.state.googleMapsApiKey) {
      return <div>Loading...</div>;
    }

    // const stripePromise = loadStripe(this.state.stripePublishableKey);

    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
        loadingComponent={<Loading />}
      >
        <ThemeProvider theme={this.muiTheme}>
          {/*
          <CustomCheckoutProvider
            stripe={stripe}
            options={{
              clientSecret: this.state.stripeClientSecret
            }}
          >
          */}
          {/* <Elements stripe={stripePromise}> */}

            <Router>
              {/* <Container maxWidth="lg" className="App" style={{border: 'solid', minWidth:'100%', height:'100vh'}}> */}
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <LoadScript googleMapsApiKey={this.state.googleMapsApiKey}>
                  <VRAppBar auth={currentUser} />


                  {/* <Grid container spacing={1}> */}
                  {/* <Grid item sm={2}>
                </Grid> */}
                  {/* <Grid item sm={12} > */}
                  <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ overflow: 'scroll' }}>
                    <Toolbar></Toolbar>
                    <MainRoutes
                      currentUser={currentUser}
                      onLoginSuccess={this.onLoginSuccess.bind(this)}
                      logOut={this.logOut.bind(this)}
                      isAuthenticated={this.isAuthenticated.bind(this)}
                    />
                  </Box>
                  {/* </Grid> */}
                  {/* </Grid> */}
                  {/* <TopBanner isAuthenticated={this.isAuthenticated()} /> */}

                </LoadScript>
              </Box>
              {/* </Container> */}
            </Router>
      
          {/* </CustomCheckoutProvider> */}
          {/* </Elements> */}
        </ThemeProvider>
      </CacheBuster>
    )
  }
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//         <button onClick={hitBackend}>Send request</button>
//       </header>
//     </div>
//   );
// }

export default App;
