import React from 'react';

// import Card from 'react-bootstrap/Card';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';


import { Box, Button, Card, CardContent, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import styles from './DeviceDetailPanel.module.css';

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import MileageChart from '../components/charts/MileageChart';
// import DeviceMap from '../components/DeviceMap';
import GoogleMapPane from '../components/maps/GoogleMapPane';

// function extractDeviceStatus(devstatus) {
//   let batteryVoltage = '-';
//   let temperature = '-';
//   let harvesterFirmwareVersion = '-';
//   let cloudModuleFirmwareVersion = '-';

//   const splitStatus = this.props.device.mostRecentData.devstatus.split(',');
//   batteryVoltage = ((splitStatus[0] / 100.0) + 2.0).toFixed(2);
//   temperature = splitStatus[1] - 60.0;
//   try {
//     harvesterFirmwareVersion = '0x' + Number(splitStatus[2]).toString(16).toUpperCase();
//   } catch {
//   }
//   try {
//     cloudModuleFirmwareVersion = splitStatus[3];
//   } catch {
//   }
//   return {
//     batteryVoltage: batteryVoltage,
//     temperatureC: temperature,
//     harvesterFirmwareVersion: harvesterFirmwareVersion,
//     cloudModuleFirmwareVersion: cloudModuleFirmwareVersion
//   };
// }


function PanelCard(props) {

  return (
    <Card className={styles.DeviceCard}>
      <CardContent>
        <Grid container alignItems="center" justify="center">
          {props.children}
        </Grid>
      </CardContent>
    </Card>
  )
}

class DeviceDetailPanel extends React.Component {


  // componentWillReceiveProps(nextProps) {
  //   this.setState({ device: nextProps.device });
  // }

  render() {
    let deviceMiles;
    if (this.props.device.currentMileage !== undefined) {
      // deviceMiles = revsToMiles(this.props.device.mostRecentData.revs, this.props.device.revsPerMile).toFixed(1);
      deviceMiles = this.props.device.currentMileage.toFixed(1);
    } else {
      deviceMiles = '-';
    }

    let lifetimeMiles;
    if (this.props.device.lifetimeMileage !== undefined) {
      lifetimeMiles = this.props.device.lifetimeMileage.toFixed(1);
    } else {
      lifetimeMiles = '-';
    }

    let deviceRevs = '-';
    if (this.props.device.mostRecentData && this.props.device.mostRecentData.revs !== undefined) {
      deviceRevs = this.props.device.mostRecentData.revs;
    }

    let postTime = '-';
    if (this.props.device.mostRecentData && this.props.device.mostRecentData.revsUpdate) {
      postTime = new Date(this.props.device.mostRecentData.revsUpdate).toString();
    }

    let batteryVoltage = '-';
    let temperature = '-';
    let harvesterFirmwareVersion = '-';
    let cloudModuleFirmwareVersion = '-';
    if (this.props.device && this.props.device.mostRecentData && this.props.device.mostRecentData.devstatus) {
      const splitStatus = this.props.device.mostRecentData.devstatus.split(',');
      batteryVoltage = ((splitStatus[0] / 100.0) + 2.0).toFixed(2);
      temperature = splitStatus[1] - 60.0;
      try {
        harvesterFirmwareVersion = '0x' + Number(splitStatus[2]).toString(16).toUpperCase();
      } catch {
      }
      try {
        cloudModuleFirmwareVersion = splitStatus[3];
      } catch {
      }
    }

    let locationUpdateTime = '-';
    if (this.props.device && this.props.device.lastLocation && this.props.device.lastLocation.ts) {
      locationUpdateTime = new Date(this.props.device.lastLocation.ts).toString();
    }

    const lastResetTime = new Date(this.props.device.lastRevReset).toLocaleString();

    //Maps element styleing
    const mapStyle = {
      // maxWidth: '450px',
      height: '240px',
      overflowX: "hidden",
      overflowY: "hidden"
    };
    const containerStyle = {
      height: '240px',
      // maxWidth: '450px'
    };

    return (
      <Box>
        <h4>Mileage</h4>
        <PanelCard>
          <Grid item sm={3}>
            <p>POST TIME</p>
            <h4>{postTime}</h4>
          </Grid>
          <Grid item sm={2}>
            <p>ODOMETER (mi)</p>
            <h3>{deviceMiles}</h3>
            {(lastResetTime && lastResetTime !== 'Invalid Date') ?
              <p><small><i>Last reset on {lastResetTime}</i></small></p>
              : <></>
            }
            <Button
              variant="contained"
              onClick={this.props.onResetOdometer}
              type="button"
            >
              Reset Odometer
            </Button>

            {/* Reset mileage button */}
          </Grid>
          <Grid item sm={2}>
            <p>LIFETIME MILEAGE (mi)</p>
            <h3>{lifetimeMiles}</h3>
            <br></br>
            <p>LIFETIME REVOLUTIONS</p>
            <h3>{deviceRevs}</h3>
          </Grid>
          <Grid item sm={5}>
            {
              (this.props.device !== undefined && this.props.revData) ?
                <MileageChart revData={this.props.revData} device={this.props.device} />
                : <h4>No Historical Data</h4>
            }
          </Grid>
          <Grid item sm={3} />
          <Grid item sm={2}>
            
          </Grid>
        </PanelCard>
        {/* <Card className={styles.DeviceCard}>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <p>DISTANCE (mi)</p>
                <h3>{deviceMiles}</h3>
              </Col>
              <Col sm={3}>
                <p>LIFETIME REVOLUTIONS</p>
                <h3>{deviceRevs}</h3>
              </Col>
              <Col sm={6}>
                <p>POST TIME</p>
                <h3>{postTime}</h3>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={3}>
                <p>BATTERY VOLTAGE (V)</p>
                <h3>{batteryVoltage.toFixed(2)}</h3>
              </Col>
              <Col sm={3}>
                <p>INTERNAL TEMPERATURE (&deg;C)</p>
                <h3>{temperature}</h3>
              </Col>
              <Col sm={3}>
                <p>ENERGY HARVESTER FIRMWARE VERSION</p>
                <h3>{harvesterFirmwareVersion}</h3>
              </Col>
              <Col sm={3}>
                <p>CLOUD MODULE FIRMWARE VERSION</p>
                <h3>{cloudModuleFirmwareVersion}</h3>
              </Col>
            </Row>
          </Card.Body>
        </Card> */}
        <br />
        {/* <h4>Historical Data</h4>
        {
          (this.props.revData.length) ?
          <MileageChart revData={this.props.revData} revsPerMile={this.props.device.revsPerMile}/>
          : <h4>No Data</h4>
        }
        <br /> */}
        <h4>Current Location</h4>
        {/* <DeviceMap devices={[this.props.device]} /> */}
        <PanelCard>
          <Grid item sm={3}>
            <p>POST TIME</p>
            <h4>{locationUpdateTime}</h4>
            <hr></hr>
            <Link to={'/triphistory/' + this.props.device.deviceID}>
              <Button size='large' variant="contained">View Location History</Button>
            </Link>
          </Grid>
          <Grid item sm={1} />
          {/* Spacer */}
          <Grid item sm={8}>
            <GoogleMapPane devices={[this.props.device]} lastLocation={this.props.device.lastLocation} mapStyle={mapStyle} containerStyle={containerStyle} />
          </Grid>
        </PanelCard>
        <br />
        {this.props.user?.admin ? (
          <>
            <h4>Device Status</h4>
            <PanelCard>
              <Grid item sm={3}>
                <p>BATTERY VOLTAGE (V)</p>
                <h3>{batteryVoltage}</h3>
              </Grid>
              <Grid item sm={3}>
                <p>INTERNAL TEMPERATURE (&deg;C)</p>
                <h3>{temperature}</h3>
              </Grid>
              <Grid item sm={3}>
                <p>ENERGY HARVESTER FIRMWARE VERSION</p>
                <h3>{harvesterFirmwareVersion}</h3>
              </Grid>
              <Grid item sm={3}>
                <p>CLOUD MODULE FIRMWARE VERSION</p>
                <h3>{cloudModuleFirmwareVersion}</h3>
              </Grid>
            </PanelCard>
          </>
        ) : ''}
      </Box>
    )
  }
}

export default DeviceDetailPanel;
