import React from 'react';

import AlertItem from '../components/AlertItem';

export default function AlertsFeed(props) {
  const alerts = [];
  if (props.devices) {
    props.devices.forEach(function(device) {
      if (device.alerts) {
        for (let i = 0; i < device.alerts.length; i++) {
          let alert = device.alerts[i];
          alert.deviceID = device.deviceID;
          alerts.push(alert);
        }
      }
    });
  }
  if (alerts.length === 0) {
    return <p>No alerts to show</p>
  }
  alerts.sort((a, b) => {
    // Sort in descending time order
    return b.timestamp - a.timestamp
  });
  const alertItems = alerts.map((alert) => {
    return <AlertItem alert={alert} key={alert._id}/>
  });
  return (
    <div>
      <h3>Alerts</h3>
      <p>Incidents View</p>
      {alertItems}
    </div>
  )
}