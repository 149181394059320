import React, { Component } from 'react';

import httpClient from '../httpClient';
import GoogleMapPane from '../components/maps/GoogleMapPane';
import { baseURL } from '../config/constants/url';

export default class LargeMapView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      assets: []
    }
  }

  componentDidMount() {
    let assetURL = new URL('/asset/', baseURL);
    // TODO: use serverside security here
    if (this.props.user?.admin) {
      assetURL = new URL('/asset/', baseURL);
    }
    httpClient.get(
      assetURL
    ).then(response => {
      this.setState({
        isLoading: false,
        assets: response.data.assets
      })
    });
  }

  render() {
    const containerStyle = {
      height: '88vh'
    }
    return (
      <div>
        <GoogleMapPane devices={this.state.assets} assetMode={true} containerStyle={containerStyle}/>
      </div>
    )
  }
}