import React, { Component } from 'react';
// import GoogleMapReact from 'google-map-react';
import { GoogleMap } from '@react-google-maps/api';
// import { fitBounds } from 'google-map-react';
import { Box, Grid } from '@mui/material';

import HistoryMarker from './HistoryMarker';
// import { mapsAPIKey } from '../../config/constants/maps';

import './marker.css';

function applyMax(accum, b) {
  if (accum === null || accum === undefined) {
    return b;
  }
  return Math.max(accum, b);
}

function applyMin(accum, b) {
  if (accum === null || accum === undefined) {
    return b;
  }
  return Math.min(accum, b);
}
class TripHistoryMap extends Component {


  constructor(props) {
    super(props);
    // if (this.props.devices.length) {
    //   let bounds = this.fitMapToAllDevices();
    //   this.state = {
    //     center: bounds.center,
    //     zoom: bounds.zoom
    //   };
    // } else {
    this.state = {
      center: props.center,
      zoom: props.zoom
    }
    // }
  }

  static defaultProps = {
    center: { // center of cont. US
      lat: 39.8283,
      lng: -98.5795
    },
    zoom: 5
  }

  handleCentering() {
    if (this.mapInstance) {
      console.log("Map loaded for zoom");
      let bounds = new window.google.maps.LatLngBounds();
      if (this.props.device?.locationHistory?.length) {
        this.props.device.locationHistory.forEach((historyItem) => {
          if (
            historyItem &&
            historyItem.location &&
            historyItem.location.coordinates.length
          ) {
            bounds.extend(
              new window.google.maps.LatLng(
                historyItem.location.coordinates[1],
                historyItem.location.coordinates[0]
              )
            );
          } else {
            
          }
        });
        // console.log(bounds);
        this.mapInstance.fitBounds(bounds);
        this.mapInstance.setZoom(Math.min(this.mapInstance.getZoom(), 10));
      }
    }
  }

  handleApiLoaded(map, maps) {

  }

  onInfoWindowClose = () => {

  }

  renderMarkers() {
    if (!this.props.device) {
      return (
        <></>
      );
    }

    const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
      return {
        x: offsetWidth + labelAnchor.x,
        y: offsetHeight + labelAnchor.y,
      };
    };

    // console.log(this.props.devices);
    console.log("Generating " + this.props.device.locationHistory.length + " location markers");
    return <>
      {
        this.props.device.locationHistory.map((historyItem, index) =>
          <HistoryMarker
            historyItem={historyItem}
            key={'history' + index}
            markerID={'history' + index}
            current={index === 0}
          />
        )
      }
    </>
  }

  // fitMapToAllMarkers() {
  //   let boundsObj = {
  //     center: this.props.center,
  //     zoom: this.props.zoom
  //   };

  //   // single device case
  //   if (
  //     this.props.devices
  //     && this.props.devices.length === 1
  //     && this.props.devices[0].lastLocation
  //     && !isNaN(this.props.devices[0].lastLocation.location.coordinates[0])
  //     && !isNaN(this.props.devices[0].lastLocation.location.coordinates[1])
  //   ) {
  //     boundsObj.center = {
  //       lat: this.props.devices[0].lastLocation.location.coordinates[1],
  //       lng: this.props.devices[0].lastLocation.location.coordinates[0]
  //     };
  //     boundsObj.zoom = 16;
  //   }

  //   else if (this.props.devices && this.props.devices.length > 1) {
  //     // let bounds = new GoogleMapReact.LatLngBounds();
  //     let bounds = {
  //       ne: { // most positive
  //         lat: null,
  //         lng: null
  //       },
  //       sw: { // most negative
  //         lat: null,
  //         lng: null
  //       }
  //     };
  //     this.props.devices.forEach(function (device) {
  //       if (device.lastLocation && device.lastLocation.location.coordinates[0] && device.lastLocation.location.coordinates[1]) {
  //         console.log("Device coords: " + device.lastLocation.location.coordinates);
  //         // bounds.extend(new GoogleMapReact.LatLng(device.lastLocation.location.coordinates[1], device.lastLocation.location.coordinates[0]));
  //         bounds = {
  //           ne: {
  //             lat: applyMax(bounds.ne.lat, device.lastLocation.location.coordinates[1]),
  //             lng: applyMax(bounds.ne.lng, device.lastLocation.location.coordinates[0]),
  //           },
  //           sw: {
  //             lat: applyMin(bounds.sw.lat, device.lastLocation.location.coordinates[1]),
  //             lng: applyMin(bounds.sw.lng, device.lastLocation.location.coordinates[0]),
  //           }
  //         }
  //       }
  //     });
  //     // FitBounds requires the mapSize. TODO: get this dynamically from the map
  //     // const size = {
  //     //   width: 640,
  //     //   height: 380
  //     // }
  //     // boundsObj = fitBounds(bounds, size);
  //     const zoomMult = 10.0;
  //     const zoom = (1.0 / (Math.max(bounds.ne.lat - bounds.sw.lat, bounds.ne.long - bounds.sw.long))) * zoomMult;

  //     boundsObj = {
  //       center: { // naive midpoint
  //         lat: (bounds.ne.lat + bounds.sw.lat) / 2.0,
  //         lng: (bounds.ne.long + bounds.sw.long) / 2.0
  //       },
  //       zoom: zoom
  //     }

  //   }
  //   return boundsObj;
  // }

  componentDidUpdate = () => {
    this.handleCentering();
  }

  componentDidMount = () => {


  }

  handleOnMapLoad = (map) => {
    console.log("Map loaded");
    // console.log("Old state:");
    // console.log(this.state);
    // let newState = this.fitMapToAllDevices();
    // newState.map = map;
    this.props.onMapLoad(map);
    this.mapInstance = map;
    setTimeout(() => {
      this.handleCentering();
    }, 1000);
    // console.log("New State:");
    // console.log(newState);
    // this.setState(newState);
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        
          <Box flexGrow={1}>
            <GoogleMap
              mapContainerStyle={this.props.containerStyle}
              center={this.state.center}
              zoom={this.state.zoom}
              onLoad={this.handleOnMapLoad}
              options={{
                gestureHandling: 'greedy'
              }}
            >
              {
                this.props.device ? this.props.device.locationHistory.map((historyItem, index) =>
                  <HistoryMarker
                    historyItem={historyItem}
                    key={'history' + index}
                    markerID={'history' + index}
                    id={'history' + index}
                    current={index === 0}
                  />
                ) : <></>
              }
            </GoogleMap>
          </Box>
   
        {/* <GoogleMapReact
          bootstrapURLKeys={{
            key: mapsAPIKey
          }}
          // defaultCenter={this.props.center}
          center={this.state.center}
          zoom={this.state.zoom}
          // defaultZoom={this.props.zoom}
          // center={boundsObj.center}
          // zoom={boundsObj.zoom}
          // yesIWantToUseGoogleMapApiInternals
          // onGoogleApiLoaded={(map, maps) => this.handleApiLoaded(map, maps)}
        >
          {this.renderMarkers()}
        </GoogleMapReact> */}
      </Box>
    );
  }
}

export default TripHistoryMap;