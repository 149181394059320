import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Toast from 'react-bootstrap/Toast';

export default function AlertItem(props) {
  const alert = props.alert;
  return (
    <Toast>
      <Toast.Header>
        <strong className='mr-auto'>{alert.deviceID}: {alert.displayType}</strong>
        <small><Moment fromNow>{alert.timestamp}</Moment></small>
      </Toast.Header>
      <Toast.Body>
        {alert.message}
      </Toast.Body>
    </Toast>
  )
}