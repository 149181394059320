import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Grid from "@mui/material/Grid";
import ToggleButton from '@mui/material/ToggleButton';
import TextField from "@mui/material/TextField";
import { DataGrid } from '@mui/x-data-grid';

import httpClient from '../httpClient';

const UPDATE_INT_MSECS = 2 * 1000;

const MQTTLogView = () => {
    const [imei, setImei] = useState('');
    const [paused, setPaused] = useState(false);
    const [logLimit, setLogLimit] = useState(100);
    const [logs, setLogs] = useState([]);
    const [status, setStatus] = useState({ message: 'Connecting', state: 'info' });


    useEffect(() => {
        const getLogs = () => {
            if (paused) return;

            const searchQuery = {
                params: {
                    imei: imei,
                    logLimit: logLimit
                }
            };
            httpClient.get('/mqtt/logs', searchQuery)
                .then((res) => {
                    setLogs(res.data.logs);
                    setStatus({ message: 'Last update ' + new Date().toLocaleString(), state: 'success' });
                }).catch((reason) => {
                    // alert("Something went wrong when getting logs from the server. See console output for details.");                    
                    console.log(reason);
                    setStatus({ message: 'Error message: ' + reason.message + ', see console for details', state: 'error' });
                });
        };

        // Update logs from server

        const interval = setInterval(() => { getLogs(); }, UPDATE_INT_MSECS);

        // Clear the interval to prevent memory leaks in the cleanup function
        return () => clearInterval(interval);
    }, [imei, logLimit, paused]);


    // Log grid setup
    const columns = [
        {
            field: 'received',
            headerName: 'Received Timestamp',
            width: 180,
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            // resizable: true,
            // valueFormatter: (params) => {
            //     if (!params.value) {
            //         return "";
            //     }
            //     return new Date(Number(params.value))
            // }
        },
        {
            field: 'ts',
            headerName: 'Data Creation Timestamp',
            type: 'dateTime',
            valueGetter: ({ value }) => value && new Date(value),
            width: 180,
            // flex: 1,

            // resizable: true,
            // valueFormatter: (params) => {
            //     if (!params.value) {
            //         return "";
            //     }
            //     return new Date(Number(params.value))
            // }
        },
        {
            field: 'deviceID',
            headerName: 'IMEI',
            width: 155,
            // resizable: true,
        },
        {
            field: 'topic',
            headerName: 'Topic',
            width: 300,
            // resizable: true,
        },
        {
            field: 'message',
            headerName: 'Message',
            width: 815,
            // resizable: true,
        }
    ]

    return (
        <>
            <Grid container spacing={2} sx={{ marginBottom: '6px' }}>
                <Grid item xs={2}>
                    <TextField
                        id="imei"
                        label="Filter by IMEI"
                        defaultValue=""
                        onChange={(e) => setImei(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>

                    <TextField
                        id='logLimit'
                        label="Number of messages to retrieve"
                        type="number"
                        InputLabelProps={{
                            shrink: true
                        }}
                        defaultValue={100}
                        onChange={(e) => setLogLimit(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <ToggleButton
                        fullWidth
                        color="error"
                        value="check"
                        selected={paused}
                        onChange={() => {
                            setPaused(!paused);
                        }}
                    >
                        Pause Log Stream
                    </ToggleButton>
                </Grid>
                <Grid item xs={6}>
                    <Alert severity={status.state}>{status.message}</Alert>
                </Grid>
            </Grid>
            <div>
                <DataGrid
                    rows={logs}
                    columns={columns}

                />
            </div>
        </>
    );
};

export default MQTTLogView;
