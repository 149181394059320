import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Box, Stack, TextField } from '@mui/material';



// import DataAccountCheckList from './dataAccountCheckList';

export default function AdminUserDetail(props) {
    const user = props.user;

    const userValues = {
        username: user.username,
        fullName: user.fullName,
        email: user.email,
        mobileNumber: user.mobileNumber,
        admin: (user.admin) ? true : false
    }

    const formik = useFormik({
        initialValues: userValues,
        onSubmit: (values) => {

        },
    });
    return (
        <>
            <h4>User Details</h4>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={4}>
                    <Box>
                        <TextField
                            labelId='username'
                            id="username"
                            name='username'
                            value={formik.values.username}
                            label="Username"
                            disabled
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>
                    <Box>
                        <TextField
                            labelId='email'
                            id="email"
                            name='email'
                            value={formik.values.email}
                            label="Email Address"
                            disabled
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>
                    <Box>
                        <TextField
                            labelId='fullName'
                            id="fullName"
                            name='fullName'
                            value={formik.values.fullName}
                            label="Full Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>
                    <Box>
                        <TextField
                            labelId='mobileNumber'
                            id="mobileNumber"
                            name='mobileNumber'
                            value={formik.values.mobileNumber}
                            label="Mobile Number for SMS"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>
                </Stack>
            </form>
        </>
    )
}
