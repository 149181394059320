import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Button, Typography, List, ListItem, ListItemText, ListItemIcon} from '@mui/material';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
// import Geocode from 'react-geocode';

import TripHistoryMap from '../components/maps/TripHistoryMap';
import { baseURL } from '../config/constants/url';
import httpClient from '../httpClient';
import { reverseGeocodeCall } from '../utils';
import withRouter from '../withRouter';

function HistoryDeviceHeading(props) {
  // Check for empty history
  if (!props.device || !props.device.locationHistory || props.device.locationHistory.length === 0) {
    return (
      <>
      <Typography variant='h6'>{props.device.name}</Typography>
      <Typography variant='body1'>
        Odometer: {props.device.currentMileage.toFixed(2)} | No location history data recorded
      </Typography>
    </>
    )
  }

  // location history should come back in date-descending order
  const endDate = props.device.locationHistory[0].ts;
  const startDate = props.device.locationHistory[props.device.locationHistory.length - 1].ts;
  return (
    <>
      <Typography variant='h6'>{props.device.name}</Typography>
      <Typography variant='body1'>
        Odometer: {props.device.currentMileage.toFixed(2)} | {new Date(startDate).toLocaleString()} to {new Date(endDate).toLocaleString()}
      </Typography>
    </>
  )
}

class TripHistoryItem extends Component {
  constructor(props) {
    super(props);
    const addressAlreadyLoaded = props.historyItem.streetLocation !== undefined;
    this.state = {
      textLocation: addressAlreadyLoaded ? props.historyItem.streetLocation : 'Loading...',
      locationLoaded: addressAlreadyLoaded
    }
  }

  componentDidMount() {
    if (!this.state.locationLoaded) {
      reverseGeocodeCall(this.props.historyItem, (address) => {
        this.setState({
          textLocation: address,
          locationLoaded: true
        });
      })
    }
  }

  render() {
    return (
      <ListItem onClick={this.props.onClick} alignItems='flex-start'>
        <ListItemIcon>
          <FmdGoodOutlinedIcon style={{ color: '#1462AC'}}/>
        </ListItemIcon>
        <ListItemText primary={
          <>
            <Typography variant="body1">{this.state.textLocation}</Typography>
            <Typography variant="caption">{new Date(this.props.historyItem.ts).toLocaleString()}</Typography>
          </>
        }/>
      </ListItem>
    )
  }
}

function TripHistoryList(props) {
  return (
    <List>
      {props.device.locationHistory.map((historyItem, index) =>
        <TripHistoryItem 
          historyItem={historyItem} 
          key={"historyListItem" + index} 
          onClick={(e) => {
            // toggle open corresponding HistoryMarker's InfoWindow
            window.google.maps.event.trigger(props.mapInstance, 'triggerOpen-history' + index);
          }}
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        />
      )}
    </List>
  )
}

class TripHistoryView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      device: null,
      deviceLoaded: false,
      mapInstance: null
    }
  }

  handleMapLoad = (mapInstance) => {
    this.setState({mapInstance: mapInstance});
  }

  componentDidMount() {
    if (!this.state.deviceLoaded) {
      var device;
      httpClient.get(
        new URL('/device/' + this.props.params.deviceID, baseURL),
      ).then(res => {
        device = res.data.device;
        return httpClient.get(
          new URL('/device/trip-history/' + this.props.params.deviceID, baseURL),
        )
      }).then(res => {
        device.locationHistory = res.data.tripHistory;
        this.setState({
          device: device,
          deviceLoaded: true
        });
      });
    }
  }


  render() {
    const mapContainerStyle = {
      height: '42vh'
    }
    return (
      <>
        <Link to={'/devicedetail/' + this.props.params.deviceID}>
          <Button size='large' variant="contained">Return to Device View</Button>
        </Link>
        <Typography variant='h6'>Trip History</Typography>
        {(!this.state.deviceLoaded) ? (
          <p>Loading...</p>
        ) : (
          <>
            <TripHistoryMap
              device={this.state.device}
              containerStyle={mapContainerStyle}
              onMapLoad={this.handleMapLoad}
            />
            <HistoryDeviceHeading device={this.state.device} />
            <TripHistoryList device={this.state.device} mapInstance={this.state.mapInstance}/>
          </>
        )}
      </>
    )
  }
}

export default withRouter(TripHistoryView);
